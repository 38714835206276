import React from 'react'
import { Link } from "react-router-dom";
import Location from '../Auth/location';
import Login from '../Auth/login';
import Autocomplete from "react-google-autocomplete";
import withRouter from '../Services/WithRouter';

class Header extends React.Component {


    constructor() {
        super();
        this.state = {
            "address" : "",
            "latitude" : "",
            "longitude" : "",
            "is_logged_in" : false,
            "cart" : [],
            "show_login" : false,
            "show_location" : false
        };
    }

    componentDidMount = async()=>{
        this.checkLocation();
    }

    componentDidUpdate = (prevProps)=>{
        if(this.props.cart.length != prevProps.cart.length){
            this.setState({'cart' : this.props.cart})
        }

        if(this.props.is_logged_in != prevProps.is_logged_in){
            this.setState({'is_logged_in' : this.props.is_logged_in})
        }
    }



    checkLocation = async()=>{
        if(this.state.latitude == "" && this.state.longitude == ""){
            var latitude = await localStorage.getItem('latitude') || "";
            var longitude = await localStorage.getItem('longitude') || "";
            var address = await localStorage.getItem('address') || "";

            if(latitude && longitude && address){
                this.setState({'latitude' : parseFloat(latitude), 'longitude' : parseFloat(longitude), 'address' : address}, ()=>{
                    this.props.setLocation(latitude, longitude, address);
                });
            }
            else{
                this.setState({'show_location' : true});
            }
            
        }
        else{
            this.setState({'show_location' : false});
        }
    }

    

    setLocation = async(address, latitude, longitude)=>{
        await this.setState({
            'address' : address,
            'latitude' : latitude,
            'longitude' : longitude,
            "show_location" : false
        }, async ()=>{
            this.props.setLocation(latitude, longitude, address);
        });    
    }


    checkLogin = async()=>{
        if(this.state.is_logged_in == true){
            this.props.navigate("/profile")
        }
        else{
            this.setState({'show_login' : true});
        }
    }


    handleLogin = async(status)=>{
        this.setState({'show_login' : false});
        if(status == true){
            this.props.setUserLogIn(true);
        }
    }


    checkCart = async() =>{
        this.props.navigate("/shop/cart")
    }

    


    render() {
        return (
            <>
                <Login show_login={this.state.show_login} handleLogin={this.handleLogin}></Login>
                <Location setLocation={this.setLocation} show_location={this.state.show_location}></Location>

                <div id="wrapper">
                    <div class="tf-top-bar bg_dark line">
                        <div class="px_15 lg-px_40">
                            <div class="tf-top-bar_wrap grid-3 gap-30 align-items-center">
                                <div class="tf-top-bar_left">
                                    <div class="d-flex gap-30 text_white fw-5">
                                        <span>(+333) 123-1688</span>
                                        <span>sayhello@ecomus.com</span>
                                    </div>
                                </div>
                                <div class="text-center overflow-hidden">
                                    <div dir="ltr" class="swiper tf-sw-top_bar" data-preview="1" data-space="0" data-loop="true" data-speed="1000" data-delay="2000">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide">
                                                <p class="top-bar-text fw-5 text_white">Spring Fashion Sale <a href="shop-default.html" title="all collection" class="tf-btn btn-line">Shop now<i class="icon icon-arrow1-top-left"></i></a></p>
                                            </div>
                                            <div class="swiper-slide">
                                                <p class="top-bar-text fw-5 text_white">Summer sale discount off 70%</p>
                                            </div>
                                            <div class="swiper-slide">
                                                <p class="top-bar-text fw-5 text_white">Time to refresh your wardrobe.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>

                <header id="header" class="header-default">
                    <div class="px_15 lg-px_40">
                        <div class="row wrapper-header align-items-center">
                            <div class="col-xl-3 col-md-4 col-4">
                                <a href="index-2.html" class="logo-header">
                                    <img src="/assets/images/logo/logo.svg" alt="logo" class="logo"/>
                                </a>
                            </div>

                            <div class="col-xl-6 col-md-4 col-4">
                                <Autocomplete
                                    apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                    onPlaceSelected={async(place) => {
                                        this.setLocation(place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng())
                                    }}
                                    options={{
                                        types: ["(regions)"],
                                        componentRestrictions: { country: "in" },
                                    }}
                                    defaultValue={this.state.address}
                                    style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}
                                />
                            </div>
                            
                            <div class="col-xl-3 col-md-4 col-4">
                                <ul class="nav-icon d-flex justify-content-end align-items-center gap-20">
                                    <li class="nav-account"><a href="javascript:;" onClick={()=>{this.checkLogin()}} class="nav-icon-item"><i class="icon icon-account"></i></a></li>
                                    <li class="nav-cart"><Link to={"/shop/cart"} class="nav-icon-item"><i class="icon icon-bag"></i><span class="count-box">{this.state.cart.length}</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </>        
        )
    }
}

export default withRouter(Header);