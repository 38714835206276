import React from "react";
import Auth from "./Auth";
const base_url = process.env.REACT_APP_STRING_API_URL;

const PostRequest = async (data, endPoint) => {
  var auth_token = await Auth.GetToken();
  return fetch(base_url + endPoint, {
    method: "POST",
    body: JSON.stringify(data),
    credentials: "include",
    headers: new Headers({
      "Content-type": "application/json",
      "Authorization": "Token " + auth_token,
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.log(error);
    });
};


const PostRequestForm = async (data, endPoint)=>{
  var auth_token =  await Auth.GetToken();
  return fetch(base_url+endPoint, {
    method: 'POST',
    body: data,
    credentials : 'include',
    headers : new Headers({
      "Authorization": "Token " + auth_token
    }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      return(responseJson);
    })
    .catch((error)=>{
      console.log(error);
      alert("Something went wrong. please try again later.");
    });   
};


const GetRequest=async (params, endPoint)=>{
  var url = base_url + endPoint
  if(Object.keys(params).length > 0){
    url =  url + '?' + new URLSearchParams(params)
  }
  var auth_token =  await Auth.GetToken();
  return fetch(url, {
    method: 'GET',
    credentials : 'include',
    headers : new Headers({
        'Content-Type':'application/json',
        "Authorization": "Token " + auth_token
      }),
  })
  .then((response) => response.json())
  .then((responseJson) => {
    return(responseJson);
  })
  .catch((error)=>{
    // alert("Something went wrong. please try again later.");
  });   
};



const DeleteRequest=async (endPoint)=>{
  var url = base_url + endPoint
  var auth_token =  await Auth.GetToken();
  return fetch(url, {
    method: 'DELETE',
    credentials : 'include',
    headers : new Headers({
        'Content-Type':'application/json',
        "Authorization": "Token " + auth_token
      }),
  })
  .then((response) => response.json())
  .then((responseJson) => {
    return(responseJson);
  })
  .catch((error)=>{
    // alert("Something went wrong. please try again later.");
  });   
};


const PutRequest=async (data, endPoint)=>{
  var url = base_url + endPoint
  var auth_token =  await Auth.GetToken();
  return fetch(url, {
    method: 'PUT',
    credentials : 'include',
    body : JSON.stringify(data),
    headers : new Headers({
        'Content-Type':'application/json',
        "Authorization": "Token " + auth_token
      }),
  })
  .then((response) => response.json())
  .then((responseJson) => {
    return(responseJson);
  })
  .catch((error)=>{
    // alert("Something went wrong. please try again later.");
  });   
};



const PutRequestForm=async (data, endPoint)=>{
  var url = base_url + endPoint
  var auth_token =  await Auth.GetToken();
  return fetch(url, {
    method: 'PUT',
    credentials : 'include',
    body : data,
    headers : new Headers({
        "Authorization": "Token " + auth_token
      }),
  })
  .then((response) => response.json())
  .then((responseJson) => {
    return(responseJson);
  })
  .catch((error)=>{
    // alert("Something went wrong. please try again later.");
  });   
};


export default {PostRequest, PostRequestForm, PutRequestForm, GetRequest, DeleteRequest, PutRequest};