import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import Api from '../Services/StadiumApi';
import withRouter from '../Services/WithRouter';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';


class Stadium extends React.Component {

    componentDidMount =()=>{
        let id = this.props.params.stadium_id
        if(id){
            this.getStadium(id);
        }
        else{

        }
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(this.props.params.stadium_id != this.state.stadium.id){
            this.getStadium(this.props.params.stadium_id);
        }
    }



    constructor(props){
        super(props);
        this.state={
            'stadium' : {
                'id' : "",
                'product_images' : [],
                'description' : "",
                "stadium_sports" : [],
                "amenities" : []
            },
            'name' : "",
            'email' :"",
            'mobile':"",
            'message' : "",
            'name_error' : "",
            'mobile_error' : "",
            'message_error': "",
            'show_form' : false
        }
        
    }



    getStadium = async(stadium_id)=>{
        window.scroll(0, 0);
        var response = await Api.GetRequest({}, 'stadium/'+stadium_id);
        if(response.status == 200){
            await this.setState({
                'stadium' : response.stadium
            });
        }
        else{
            toast.error("Product details doesn't exists.")
        }

    }

    
    doEnquire = async()=>{
        this.setState({'show_form' : true})
    }


    handleClose = async()=>{
        this.setState({'show_form' : false})
    }


    validateForm = async()=>{
        var status = true;
        var data = {
            'name' : this.state.name.trim(),
            'email' : this.state.email.trim(),
            'mobile' : this.state.mobile.trim(),
            'message' : this.state.message.trim(),
            'stadium' : this.state.stadium.id
        }
        if(data.name.trim().length == 0){
            status = false;
            this.setState({'name_error' : "Please enter valid name."});
        }
        else{
            this.setState({'name_error' : ""});   
        }
        if(data.mobile == "0000000000") {
            status = false;
            this.setState({'mobile_error' : "Please enter correct mobile number."})
        }
        else{
            this.setState({'mobile_error' : ""});
        }
        
        if(data.mobile[0] == "0") {
            status = false;
            this.setState({'mobile_error' : "Please enter correct mobile number."})
        }
        else{
            this.setState({'mobile_error' : ""})
        }

        if(data.message.trim().length == 0){
            status = false;
            this.setState({'message_error' : "Please enter message."});
        }
        else{
            this.setState({'message_error' : ""});   
        }
        
        var regx = new RegExp("^[0-9]{10}$");
        var res = await regx.test(data.mobile);
        if (res) {
            
        }
        else{
            status = false;
            this.setState({'mobile_error' : "Please enter correct mobile number."})
        }

        if(status == true){
            this.enquireNow(data);
        }
        else{

        }
    }



    enquireNow = async(data)=>{
        var response = await Api.PostRequest(data, 'enquiries');
        if(response.status == 200){
            await this.setState({'show_form' : false},()=>{
                toast.success(response.message);
            });

        }
        else{
            await this.setState({'show_form' : false},()=>{
                toast.error(response.message);
            });
        }
    }


    render() {
        return (
            <>
                <Modal show={this.state.show_form} onHide={()=>{this.handleClose()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enquiry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="justify-content-center d-flex align-items-center flex-column">
                        <>
                            <div className="input-group d-flex flex-column">
                                <label> <i className="fa fa-user"></i> Name</label>
                                <input onChange={(event) => {this.setState({"name" : event.target.value})}} className="form-control w-100" type="text" name="name" placeholder=""/>
                            </div>
                            <small className="mt-1" style={{'color' : 'red', textAlign:'left', 'width' : '100%'}}>{this.state.name_error}</small>

                            <div className="input-group my-2 d-flex flex-column">
                                <label> <i className="fa fa-mobile"></i> Mobile</label>
                                <input onChange={(event) => {this.setState({"mobile" : event.target.value})}} className="form-control w-100" type="text" name="mobile" placeholder=""/>
                            </div>
                            <small className="mt-1" style={{'color' : 'red', textAlign:'left', 'width' : '100%'}}>{this.state.mobile_error}</small>

                            <div className="input-group my-2 d-flex flex-column">
                                <label> <i class="fa fa-envelope"></i> Email</label>
                                <input onChange={(event) => {this.setState({"email" : event.target.value})}} className="form-control w-100" type="email" name="email" placeholder=""/>
                            </div>



                            <div className="input-group my-2 d-flex flex-column">
                                <label> <i class="fa fa-comment"></i> Message</label>
                                <textarea onChange={(event) => {this.setState({"message" : event.target.value})}} className="form-control w-100"></textarea>
                            </div>
                            <small className="mt-1" style={{'color' : 'red', textAlign:'left', 'width' : '100%'}}>{this.state.message_error}</small>

                            <button onClick={()=>{this.validateForm()}} class="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn btn-add-to-cart w-100" href="javascript:;"><span>Submit</span></button>
                        </>
                    </Modal.Body>
                </Modal>

                <section class="flat-spacing-4 pt_0">
                    <div class="tf-main-product section-image-zoom">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="tf-product-media-wrap sticky-top">
                                        <div class="thumbs-slider">
                                            
                                            <div dir="ltr" class="swiper tf-product-media-main" id="gallery-swiper-started">
                                                <Swiper class="swiper-wrapper">
                                                    <SwiperSlide class="swiper-slide" data-color="beige">
                                                        <a href="javascript:;" target="_blank" class="item" data-pswp-width="770px" data-pswp-height="1075px">
                                                            <img class="tf-image-zoom lazyload" data-zoom={process.env.REACT_APP_MEDIA_URL + this.state.stadium.image} data-src={process.env.REACT_APP_MEDIA_URL + this.state.stadium.image} src={process.env.REACT_APP_MEDIA_URL + this.state.stadium.image} alt=""/>
                                                        </a>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="tf-product-info-wrap position-relative">
                                        <div class="tf-zoom-main"></div>
                                        <div class="tf-product-info-list other-image-zoom">
                                            <div class="tf-product-info-title">
                                                <h5>{this.state.stadium.name}</h5>
                                            </div>
                                            
                                            <div class="tf-product-info-price">
                                                <div class="price-on-sale">₹ {this.state.stadium.price}</div>
                                            </div>
                                            <div class="tf-product-info-liveview">
                                                <p class="fw-6">Many people are viewing this right now</p>
                                            </div>
                                            
                                            
                                            <div className="justify-content-start d-flex align-items-center flex-row my-2">
                                                <label>Address : </label>
                                                <span className="mx-3">{this.state.stadium.address}</span>
                                            </div>

                                            <div className="justify-content-start d-flex align-items-center flex-row my-2">
                                                <label>Timing : </label>
                                                <span className="mx-3">{moment(this.state.stadium.opening_time, "hh:mm").format("hh:mm A")} - {moment(this.state.stadium.closing_time, "hh:mm").format("hh:mm A")}</span>
                                            </div>
                                            
                                            
                                            <h6 className="w-100 my-4" style={{'textAlign' : "left", 'fontWeight':"bold"}}>Sports</h6>
                                            
                                            <div className="justify-content-start d-flex align-items-center flex-row flex-wrap">
                                                {this.state.stadium.stadium_sports.map((sport)=>{
                                                    return(
                                                        <div className="justify-content-start d-flex-align-items-center flex-row m-2" style={{backgroundColor:'#EDEDED'}}>
                                                            <img style={{'width' : '30px'}} src={process.env.REACT_APP_SERVER_URL + sport.image} alt="zoom" />
                                                            <span className="mx-2">{sport.name}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            
                                            <h6 className="w-100 my-4" style={{'textAlign' : "left", 'fontWeight':"bold"}}>Amenities</h6>
                                            <div className="justify-content-start d-flex align-items-center flex-row flex-wrap">
                                                {this.state.stadium.amenities.map((amenity)=>{
                                                    return(
                                                        <div className="justify-content-start d-flex-align-items-center flex-row m-2" style={{backgroundColor:'#EDEDED'}}>
                                                            <span className="mx-2">{amenity}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            
                                            <div class="tf-product-info-buy-button">
                                                <a href="javascript:void(0);" onClick={()=>{this.doEnquire()}} class="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn btn-add-to-cart w-100"><span>Enquire Now</span></a>   
                                            </div>
                                           
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </section>

                <section class="flat-spacing-17 pt_0">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="widget-tabs style-has-border">
                                    <ul class="widget-menu-tab">
                                        <li class="item-title active">
                                            <span class="inner">Description</span>
                                        </li>
                                    </ul>
                                    <div class="widget-content-tab">
                                        <div class="widget-content-inner active">
                                            <div style={{'textAlign' : 'left'}}>
                                                {parse(this.state.stadium.description)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>        
        )
    }
}

export default withRouter(Stadium);


