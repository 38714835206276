import React from 'react';
import * as ReactDOM from 'react-dom';
import Api from '../Services/EcomApi';
import withRouter from '../Services/WithRouter';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

class Order extends React.Component{


    componentDidMount = async()=>{
        let order_id = await this.props.params.order_id
        if(order_id){
            this.getOrderDetails(order_id);
        }
        
    }

    

    constructor(props){
        super(props);
        this.state = {
            'order' : {
                'order_items' : [],
                'total_price' : 0,
                'total_discount' : 0,
                'coupon_discount' : 0,
                'referral_discount' : 0
            }
        }
    }


    getOrderDetails = async(order_id)=>{
        var params = {
            'order_id' : order_id
        }
        var response = await Api.GetRequest(params, 'order');
        if(response.status == 200){
            this.setState({
                'order' : response.order
            })
        }
    }

    
    render() {
        return(
            <>   
                <div class="flat-spacing-6">
                    <div className="container mb-1">
                        <div className="card p-5">
                            
                            <div className="row">
                                <div className="col text-center">
                                    <img className="img-fluid mb-5 mh-70" src="/assets/images/logo/logo.svg" alt="LOGO" />
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-lg-4 col-md-6" style={{'textAlign' : 'left'}}>
                                    <h6 className='my-3'><b>Order Details :</b></h6>
                                    <span className="text-muted">Order Id :</span>&emsp; {this.state.order.order_id}<br></br>
                                    <span className="text-muted">Order Date :</span>&emsp; {moment(this.state.order.created_at).format('lll')}<br></br>

                                    <br></br>
                                    <br></br>
                                </div>

                                <div className="col-lg-5 col-md-6" style={{'textAlign' : 'left'}}>
                                    <h6 className='my-3'><b>Billing Address :</b></h6>
                                    <span className="text-muted">Name: </span>&emsp; {this.state.order.name}<br></br>
                                    <span className="text-muted">Phone: </span>&emsp; {this.state.order.mobile}<br></br>
                                    <span className="text-muted">Address: </span>&emsp; {this.state.order.address},{this.state.order.city}, {this.state.order.zipcode}<br></br>
                                </div>

                                <div className="col-lg-3 col-md-6" style={{'textAlign' : 'left'}}>
                                    <h6 className='my-3'><b>Payment Details :</b></h6>
                                        {this.state.order.payment_status == 0 &&
                                            <>
                                                <span className="text-danger">Payment status: </span>&emsp; Pending<br></br>
                                            </>
                                            
                                        }
                                        {this.state.order.payment_status == 1 &&
                                            <>
                                                <span className="text-success">Payment status: </span>&emsp; Complete<br></br>
                                            </>
                                        }
                                        {this.state.order.payment_mode == 0 &&
                                            <>
                                                <span className="text-success">Payment mode: </span>&emsp; Online<br></br>
                                            </>
                                            
                                        }
                                        {this.state.order.payment_mode == 1 &&
                                            <>
                                                <span className="text-success">Payment mode: </span>&emsp; COD<br></br>
                                            </>
                                        }
                                    
                                </div>
                            </div>
                            
                            <div className="row my-5">
                                <div className="col-12 my-account-content account-order">
                                    <div class="wrap-account-order">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="fw-6">
                                                        <b>Product</b>
                                                    </th>
                                                    <th className="fw-6">
                                                        <b>Color</b>
                                                    </th>
                                                    <th className="fw-6">
                                                        <b>Size</b>
                                                    </th>
                                                    <th className="fw-6">
                                                        <b>Quantity</b>
                                                    </th>
                                                    <th className="fw-6">
                                                        <b>Price</b>
                                                    </th>
                                                    <th className="fw-6">
                                                        <b>Discount</b>
                                                    </th>
                                                    <th className="fw-6">
                                                        <b>Sub Total</b>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.order.order_items.map((product)=>{
                                                return(
                                                    <tr class="tf-order-item">
                                                        <td style={{'verticalAlign' : 'middle','alignItems' :'center', 'display' : 'flex', 'justifyContent' : 'flex-start'}} className="">
                                                            <div  className='d-flex flex-row align-items-center'>
                                                                <img className="lazy mx-2" src={process.env.REACT_APP_MEDIA_URL+'/'+ product.image} alt="Product" style={{'width':"70px", 'height' : '70px'}}/>
                                                                <div>{product.name}</div>
                                                            </div>
                                                            
                                                        </td>
                                                        <td style={{'verticalAlign' : 'middle'}} className="px-0">
                                                            {product.color &&
                                                                <span>{product.color}</span>
                                                            }
                                                            {!product.color &&
                                                                <span>-</span>
                                                            }
                                                        </td>
                                                        <td style={{'verticalAlign' : 'middle'}} className="px-0">
                                                            {product.size &&
                                                                <span>{product.size}</span>
                                                            }
                                                            {!product.size &&
                                                                <span>-</span>
                                                            }
                                                        </td>
                                                        <td style={{'verticalAlign' : 'middle'}} className="px-0">
                                                            {product.quantity}
                                                        </td>
                                                        <td style={{'verticalAlign' : 'middle'}} className="px-0">₹  {product.price.toFixed(0)}</td>
                                                        <td style={{'verticalAlign' : 'middle'}} className="px-0">{product.discount} % OFF</td>
                                                        <td style={{'verticalAlign' : 'middle'}} className="px-0 text-right">₹  {((product.price * product.quantity) - product.net_discount).toFixed(0)}</td>
                                                    </tr>
                                                )
                                            })}
                                            

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                                    
                        
                            <div  className="row my-5">
                                <div className="col-12 my-account-content account-order">
                                    <div className="wrap-account-order d-flex justify-content-end" style={{'width' : '100%', minWidth : "auto"}}>
                                        <table className='table table-striped w-50 bill-table'>
                                            <tbody>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>MRP Total</th>
                                                    <td className="px-5" style={{'textAlign' : 'right', fontWeight : 'bold'}}>₹ {this.state.order.total_price.toFixed(0)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Discount</th>
                                                    <td className="px-5" style={{'textAlign' : 'right',fontWeight : 'bold'}}>-₹ {this.state.order.total_discount.toFixed(0)}</td>
                                                </tr>

                                                {this.state.referral_discount > 0 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Referral Discount</th>
                                                        <td className="px-5" style={{'textAlign' : 'right', fontWeight : 'bold'}}>-₹ {this.state.order.referral_discount.toFixed(0)}</td>
                                                    </tr>
                                                }
                                                {this.state.coupon_discount > 0 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Coupon Discount</th>
                                                        <td className="px-5" style={{'textAlign' : 'right', fontWeight : 'bold'}}>-₹ {this.state.order.coupon_discount.toFixed(0)}</td>
                                                    </tr>
                                                }
                                                
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Total Price</th>
                                                    <td className="px-5" style={{'textAlign' : 'right', fontWeight : 'bold'}}>₹ {this.state.order.total_price - this.state.order.total_discount - this.state.order.referral_discount - this.state.order.coupon_discount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



export default withRouter(Order);