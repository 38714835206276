import React, { Component } from "react";
import withRouter from '../Services/WithRouter';
import EcomApi from '../Services/EcomApi';
import Api from '../Services/StringApi';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import Autocomplete from "react-google-autocomplete";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Login from "../Auth/login";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button  from 'react-bootstrap/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


class StringingCheckout extends Component{


    pickup_times =  [
        { label: '11:00 AM - 2:00 PM', value: '0' },
        { label: '7:00 PM - 9:00 PM', value: '1' }
    ];

    componentDidMount = async()=>{
        await this.setState({'booking' : this.props.location.state},()=>{
            this.getCoupons();
            this.getAddons();
            this.getFreeProduct();
            this.calculateStringPrice();
        });
    }


    calculateStringPrice = async() =>{
        var string_price = 0;
        this.state.booking.string_items.map((item)=>{
            string_price = string_price + (item.string_price * item.quantity)
        });
        this.setState({'string_price' : string_price})
    }



    getAddons = async() => {
        var data = {
            'latitude' : this.state.booking.latitude,
            'longiude' : this.state.booking.longitude,
            'service_type' : this.state.booking.service_type
        }
        var response = await EcomApi.GetRequest(data, 'addons');
        if(response.status == 200){
            this.setState({'string_addons' : response.products});
        }
        else{
            this.setState({'show_login' : true});
        }
    }


    getCoupons = async() => {
        var response = await Api.GetRequest({}, 'coupons');
        if(response.status == 200){
            this.setState({'coupons' : response.coupons});
        }
        else{

        }
    }



    getFreeProduct = async() => {
        var response = await EcomApi.GetRequest({}, 'free-product');
        if(response.status == 200){
            response.product.size ="";
            response.product.color ="";
            this.setState({'product' : response.product});
        }
        else{

        }
    }



    constructor(props){
        super(props);
        this.state={
            "booking": {
                "address": "",
                "latitude" : null,
                "longitude" : null,
                "slot_date": "",
                "slot_time": "",
                "name": "",
                "string_items" : []
            },
            'string_addons' : [],
            'addons' : [],
            'show_addon' : false,
            'addon_price' : 0,
            'show_login' : false,
            'string_price' : 0,
            'show_addon_product' : false,
            'payment_mode' : "0",
            'product_id' : "",
            'show_coupons' : false,
            'coupons' : [],
            
            'refer_code' : "",
            'coupon' : {},
            'discount_type' : "",
            
            'referral_discount' : 0,
            'coupon_discount' : 0,
            'addon_product' : {
                'product_colors' : [],
                'product_sizes' : [],
                'color' : "",
                'size' : "",
                'name' : "",
                'image' : ""   
            },
            'product' : {
                'product_colors' : [],
                'product_sizes' : [],
                'product_images' : [],
                'color' : "",
                'size' : "",
                'name' : "",
                'image' : "",
                'description' : ""
            },
            'coupon_code' : "",
            'coupon_error' : ""   
        }
        
    }


    getAddonDetails  = async(product)=>{
        product.size="";
        product.color="";
        product.product_images.push({'image' : product.image})
        this.setState({
            'show_addon_product' : true,
            'addon_product' : product, 
        })
    }


    deleteAddons = async(index)=>{
        var addons = this.state.addons;
        addons.splice(index, 1)
        await this.setState({'addons' : addons});
        this.calculateAddonPrice();
    }


     verifyUser = async()=>{
        var response = await Api.GetRequest({}, 'verify-user');
        if(response.status == 200){
            if(this.state.payment_mode == 0){
                this.createOrder();
            }
            else{
                var string_amount =  parseInt(this.state.string_price);
                var addon_amount = parseInt(this.state.addon_price);
                var total_amount = addon_amount + string_amount - parseInt(this.state.referral_discount) - parseInt(this.state.coupon_discount);
                this.doBooking(total_amount, string_amount, addon_amount, 1, parseInt(this.state.referral_discount), parseInt(this.state.coupon_discount));
            }
        }
        else{
            this.setState({'show_login' : true});
        }
    }


    createOrder = async()=>{
        var data = {
            'string_amount' : parseInt(this.state.string_price),
            'addon_amount' : this.state.addon_price,
            'referral_discount' : this.state.referral_discount,
            'coupon_discount' : this.state.coupon_discount
        };
        var response = await Api.PostRequest(data, 'string-create-order');
        if(response.status == 200){
            this.makePayment(response.order, response.total_amount, response.string_amount, response.addon_amount, response.razorpay_key_id, response.referral_discount, response.coupon_discount);
        }
    }



    makePayment = async(order, total_amount, string_amount, addon_amount, razorpay_key_id, referral_discount, coupon_discount)=>{
        var options = {
            description: '',
            image: 'https://i.imgur.com/3g7nmJC.jpg',
            currency: 'INR',
            key: razorpay_key_id,
            amount: order.amount.toString(),
            name: "",
            order_id: order.id,//Replace this with an order_id created using Orders API.
            prefill: {
              email: '',
              contact: '',
              name: ''
            },
            theme: {color: '#000000'},
            handler: async (data) => {
                try {
                    this.doBooking(total_amount, string_amount, addon_amount, 0, referral_discount, coupon_discount)
                } catch (err) {
                  console.log(err);
                }
            },
        }
        
        var razorpay = new window.Razorpay(options);
        razorpay.open();
    }



    doBooking = async(total_amount, string_amount, addon_amount, payment_mode, referral_discount, coupon_discount)=>{
        var data = {
            "address": this.state.booking.address,
            "latitude" : this.state.booking.latitude,
            "longitude" : this.state.booking.longitude,
            "slot_date": moment(this.state.booking.slot_date, 'DD-MM-YYYY').format("YYYY-MM-DD"),
            "slot_time": this.state.booking.slot_time,
            "name": this.state.booking.name,
            "service_type": this.state.booking.service_type,
            "addons" : this.state.addons,
            "total_amount" : total_amount,
            "string_amount" : string_amount,
            "addon_amount" : addon_amount,
            "string_items" : this.state.booking.string_items,
            "payment_mode" : payment_mode,
            "referral_discount" : referral_discount,
            "coupon_discount" : coupon_discount,
            "refer_code" : this.state.refer_code,
            "coupon_id" : "",
            "free_product" : ""
        };
        if(this.state.discount_type == 1){
            data.coupon_id = "";
            data.coupon_discount = 0;
            data.free_product = "";
        }
        else if(this.state.discount_type == 2){
            data.coupon_id = this.state.coupon.id;
            data.refer_code = "";
            data.referral_discount = 0;
            data.free_product = "";
        }
        else if(this.state.discount_type == 3){
            data.coupon_id = "";
            data.coupon_discount =0;
            data.refer_code = "";
            data.referral_discount = 0;
            data.free_product = JSON.stringify({'name' : this.state.product.name, 'image' : this.state.product.image, 'color' : this.state.product.color, 'size' : this.state.product.size});
        }
        else{
            data.coupon_id = "";
            data.coupon_discount =0;
            data.refer_code = "";
            data.referral_discount = 0;
            data.free_product = "";
        }
        var response = await Api.PostRequest(data, 'string-service-booking');
        if(response.status == 200){
            await toast.success(response.message);
            this.props.navigate("/home")
        }
        else{
            await toast.error(response.messsage);
        }
    }


    handleLogin = async()=>{
        this.setState({'show_login' : false});
        this.props.setUserLogIn(true);
        this.getCart();
        this.props.fetchCart();
    }
 
 
    closeLogin = async()=>{
        this.setState({'show_login' : false});
    }

    

    calculateAddonPrice = async()=>{
        const addon_price = await this.state.addons.reduce((accumulator, element) => accumulator + element.net_amount * element.quantity,0);
        await this.setState({'addon_price' : addon_price}, ()=>{
            this.calculateOtherDiscount();
        });
        
    }


    calculateOtherDiscount = async()=>{
        if(this.state.discount_type == 1){
            var referral_discount = await (this.state.string_price + this.state.addon_price) * this.state.refer_code_discount / 100;
            this.setState({'referral_discount' : referral_discount});
        }
        if(this.state.discount_type == 2){
            var coupon_discount = await (this.state.string_price + this.state.addon_price) * this.state.coupon.discount / 100;
            this.setState({'coupon_discount' : coupon_discount});
        }
    }


    setCoupon = async(coupon)=>{
        await this.setState({
            'coupon' : coupon, 
            'discount_type' : 2,
            'refer_code' : "",
            'referral_discount' : 0,
            'show_coupons' : false,
            'product'  : {}
        });
        await this.calculateOtherDiscount();
        await this.getFreeProduct();
    }


    applyReferCode = async()=>{
        if(this.state.refer_code.length > 0){
            var data = {
                'refer_code': this.state.refer_code
            }
            var response = await Api.PostRequest(data, 'apply-refer-code');
            if(response.status == 200){
                await toast.success(response.message);
                await this.setState({'coupon' : {}, 'discount_type' : 1, 'refer_code_discount' : response.referral_discount,'coupon_discount' : 0, 'product' : {}}, ()=>{
                    this.calculateOtherDiscount();
                });
            }
            else{
                await toast.error(response.message);
                this.setState({'coupon' : {}, 'discount_type' : "", 'refer_code' : "", 'refer_code_discount' : "", 'referral_discount' : 0, 'coupon_discount' : 0, 'product' : {}});
            }
            this.getFreeProduct();
        }
    }


    removeReferCode = async()=>{
        await this.setState({'coupon' : {}, 'discount_type' : "", 'refer_code' : "", 'refer_code_discount' : "", 'referral_discount' : 0, 'coupon_discount' : 0, 'product' : {}});
        await this.calculateOtherDiscount();
        this.getFreeProduct();
    }


    removeCouponCode = async()=>{
        await this.setState({'coupon' : {}, 'discount_type' : "", 'refer_code' : "", 'refer_code_discount' : "", 'referral_discount' : 0, 'coupon_discount' : 0, 'product' : {}});
        await this.calculateOtherDiscount();
        this.getFreeProduct();
    }

    removeFreeProduct = async()=>{
        await this.setState({'coupon' : {}, 'discount_type' : "", 'refer_code' : "", 'refer_code_discount' : "", 'referral_discount' : 0, 'coupon_discount' : 0, 'product' : {}});
        await this.calculateOtherDiscount();
        this.getFreeProduct();
    }

    changeProductColor = async(color)=>{
        var product = this.state.product;
        product.color = color;
        this.setState({'product' : product});
    }

    changeProductSize = async(size)=>{
        var product = this.state.product;
        product.size = size;
        this.setState({'product' : product});
    }


    applyFreeProduct = async()=>{
        if(this.state.product.color.length == 0){
            await toast.error("Please select color.");
        }
        else if(this.state.product.size.length == 0){
            await toast.error("Please select size.");
        }
        else{
            this.setState({
                'coupon' : {}, 
                'discount_type' : 3,
                'refer_code' : "",
                'referral_discount' : 0,
                'show_coupons' : false
            });
            await toast.success("Product added successfully.");
        }
    }

    closeAddons = async()=>{
        this.setState({'show_addon': false});
    }

    closeAddonProduct = async()=>{
        this.setState({'show_addon_product': false});
    }


    setColor = async(color)=>{
        if(this.state.addon_product.color != color){
            await this.setState({ addon_product:{
                ...this.state.addon_product,
                'color': color,
                'size' : ""
            }});
            await this.getSizes();
        }      
    }


    getSizes = async()=>{
        var params = {
            'product_id' : this.state.addon_product.id,
            'color' : this.state.addon_product.color
        };
        var response = await EcomApi.GetRequest(params, 'sizes')
        if(response.status == 200){
            await this.setState({ addon_product:{
                ...this.state.addon_product,
                'product_sizes': response.sizes
            }});
        }

    }



    setSize = async(size)=>{
        this.setState({'size' : size});
        await this.setState({ addon_product:{
            ...this.state.addon_product,
            'size': size
        }});
    }


    addToAddon = async()=>{
        var data = {
            'id' : this.state.addon_product.id,
            'name' : this.state.addon_product.name,
            'net_amount' : this.state.addon_product.net_amount,
            'price' : this.state.addon_product.price,
            'discount' : this.state.addon_product.discount,
            'color' : this.state.addon_product.color,
            'size' : this.state.addon_product.size,
            'quantity' : 1,
            'max_quantity' : this.state.addon_product.quantity,
            'image' : this.state.addon_product.image
        }
        if(this.state.addon_product.product_colors.length > 0 && data.color == ""){
            toast.error("Please select color.");
        }
        else if(this.state.addon_product.product_sizes.length > 0 && data.size == ""){
            toast.error('Please select size.');
        }
        else{
            var addons = this.state.addons;
            const index = addons.findIndex(element => data.id === element.id && data.color === element.color && data.size === element.size);
            if(index  == -1){
                addons.push(data);
            }
            this.setState({'addons' : addons, "show_addon_product" : false},()=>{
                this.calculateAddonPrice();
            });
            toast.success('Addon added.');
        }
    }


    changeQuantity = async(value, index, max_quantity)=>{
        var addons = this.state.addons;
        console.log(index);
        if(value == 1){
            if(addons[index].quantity < max_quantity){
                addons[index].quantity = addons[index].quantity + value;
            }
            else{
                await toast.error("Max quantity for this product is " + max_quantity);
            }
        }
        else{
            if(addons[index].quantity  == 1){
                await toast.error("Min quantity should be 1." + max_quantity);
            }
            else{
                addons[index].quantity = addons[index].quantity - 1;
               
            }
        }
        
        await this.setState({'addons' : addons});
        this.calculateAddonPrice()
    }


    deleteAddons = async(index)=>{
        var addons = this.state.addons;
        addons.splice(index, 1)
        await this.setState({'addons' : addons});
        this.calculateAddonPrice();
    }


    setPaymentMode = async(payment_mode) =>{
        this.setState({'payment_mode' : payment_mode});
    }


    checkCouponCode = async()=>{
        var data = {
            'coupon_code' : this.state.coupon_code.trim()
        }
        if(data.coupon_code.length > 0){
            var response = await EcomApi.PostRequest(data, 'coupon');
            if(response.status == 200){
                await this.setState({
                    'coupon' : response.coupon, 
                    'discount_type' : 2,
                    'refer_code' : "",
                    'referral_discount' : 0,
                    'show_coupons' : false,
                    'product'  : {}
                }, async ()=>{
                    await this.calculateOtherDiscount();
                    await this.getFreeProduct();
                });       
            }
            else{
                this.setState({'coupon_error' : "Please enter valid coupon code."})
            }
        }
        else{
            this.setState({'coupon_error' : "Please enter valid coupon code."})
        }
    }


    render(){
        return(
            <>
                <Login show_login={this.state.show_login} handleLogin={this.handleLogin}/>

                <Modal show={this.state.show_addon} size="lg" onHide={()=>{this.closeAddons()}}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize:'15px'}}>Addons</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{"backgroundColor": "#EDEDED"}} className="justify-content-center d-flex align-items-center flex-column">
                        <div className="row g-3">
                            {this.state.string_addons.map((product, index)=>{
                                return(
                                    <div onClick={()=>{this.getAddonDetails(product)}} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
                                        <div class="card-product style-4">
                                            <div class="card-product-wrapper">
                                                <a href="javascript:;" class="product-img">
                                                    <img class="lazyload img-product" data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt="image-product"/>
                                                    <img class="lazyload img-hover" data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt="image-product"/>
                                                </a>
                                                
                                                <a href="javascript:;" class="btn-quick-add quick-add">View</a>
                                            </div>
                                            <div class="card-product-info">
                                                <a href="javascript:;" class="title link">{product.name}</a>
                                                <span class="price">
                                                    {product.discount == 0 &&
                                                        <>
                                                            ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                        </>
                                                    }
                                                    {product.discount > 0 &&
                                                        <>
                                                            <del>₹ {product.price}</del>
                                                            <span className="bg-success mx-2 px-2" style={{'color' : '#FFFFFF',  borderRadius : 50}}>{product.discount} % OFF</span>
                                                            <span>₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}</span>
                                                        </>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        
                        
                    </Modal.Body>
                </Modal>


                <Modal show={this.state.show_addon_product} size="lg" onHide={()=>{this.closeAddonProduct()}}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize:'15px'}}>Addon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{"backgroundColor": "#EDEDED"}} className="justify-content-center d-flex align-items-center flex-column">
                        <section class="flat-spacing-4 pt_0">
                            <div class="tf-main-product section-image-zoom">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="tf-product-media-wrap sticky-top">
                                                <div class="thumbs-slider">
                                                    
                                                    <div dir="ltr" class="swiper tf-product-media-main" id="gallery-swiper-started">
                                                        <Swiper class="swiper-wrapper">
                                                            {this.state.addon_product.product_images.map((image)=>{
                                                                return(
                                                                    <SwiperSlide class="swiper-slide" data-color="beige">
                                                                        <a href="javascript:;" target="_blank" class="item" data-pswp-width="770px" data-pswp-height="1075px">
                                                                            <img class="tf-image-zoom lazyload" data-zoom={process.env.REACT_APP_MEDIA_URL + image.image} data-src={process.env.REACT_APP_MEDIA_URL + image.image} src={process.env.REACT_APP_MEDIA_URL + image.image} alt=""/>
                                                                        </a>
                                                                    </SwiperSlide>
                                                                )
                                                            })

                                                            }
                                                            
                                                        </Swiper>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="tf-product-info-wrap position-relative">
                                                <div class="tf-zoom-main"></div>
                                                <div class="tf-product-info-list other-image-zoom">
                                                    <div class="tf-product-info-title">
                                                        <h5>{this.state.addon_product.name}</h5>
                                                    </div>
                                                    <div class="tf-product-info-badges">
                                                        <div class="badges">Best seller</div>
                                                        <div class="product-status-content">
                                                            <i class="icon-lightning"></i>
                                                            <p class="fw-6">Selling fast! many people have  this in their carts.</p>
                                                        </div>
                                                    </div>
                                                    <div class="tf-product-info-price">
                                                        {this.state.addon_product.discount == 0 &&
                                                            <>
                                                                <div class="price-on-sale">₹ {(this.state.addon_product.price - (this.state.addon_product.price * this.state.addon_product.discount)/100).toFixed(0)}</div>
                                                            </>
                                                        }
                                                        {this.state.addon_product.discount > 0 &&
                                                            <>
                                                                <div class="price-on-sale">₹ {(this.state.addon_product.price - (this.state.addon_product.price * this.state.addon_product.discount)/100).toFixed(0)}</div>
                                                                <div class="compare-at-price">₹ {this.state.addon_product.price}</div>
                                                                <div class="badges-on-sale"><span>{this.state.addon_product.discount}</span>% OFF</div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div class="tf-product-info-liveview">
                                                        <p class="fw-6">Many people are viewing this right now</p>
                                                    </div>
                                                    
                                                    
                                                    <div class="tf-product-info-variant-picker">
                                                        {this.state.addon_product.product_colors.length > 0 &&
                                                            <div class="variant-picker-item">
                                                                <div class="quantity-title fw-6" style={{'textAlign' : "left"}}>Color</div>
                                                                <div class="variant-picker-values mt-2">
                                                                    <select class="tf-select" value={this.state.color} onChange={(event)=>this.setColor(event.target.value)} style={{'color' : '#000000', background : '#ffffff'}}>
                                                                        <option value="">Select Color</option>
                                                                        {this.state.addon_product.product_colors.map((color)=>{
                                                                            return(
                                                                                <option value={color}>{color}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                        {this.state.addon_product.product_sizes.length > 0 &&
                                                            <div class="variant-picker-item">
                                                                <div class="quantity-title fw-6" style={{'textAlign' : "left"}}>Size</div>
                                                                <div class="variant-picker-values">
                                                                    <select class="tf-select" value={this.state.size} onChange={(event)=>this.setSize(event.target.value)} style={{'color' : '#000000', background : '#ffffff'}}>
                                                                        <option value="">Select Size</option>
                                                                        {this.state.addon_product.product_sizes.map((size)=>{
                                                                            return(
                                                                                <option value={size}>{size}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    
                                                    <div class="tf-product-info-buy-button">
                                                        <a href="javascript:void(0);" onClick={()=>{this.addToAddon()}} class="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn btn-add-to-cart w-100"><span>Add to cart</span></a>   
                                                    </div>
                                                
                                                    <div class="tf-product-info-delivery-return">
                                                        <div class="row">
                                                            <div class="col-xl-6 col-12">
                                                                <div class="tf-product-delivery">
                                                                    <div class="icon">
                                                                        <i class="icon-delivery-time"></i>
                                                                    </div>
                                                                    <p>Estimate delivery times: <span class="fw-7">3-7 days</span></p>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6 col-12">
                                                                <div class="tf-product-delivery mb-0">
                                                                    <div class="icon">
                                                                        <i class="icon-return-order"></i>
                                                                    </div>
                                                                    <p>Return within <span class="fw-7">30 days</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        {this.state.addon_product.description &&
                            <section class="flat-spacing-17 pt_0">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="widget-tabs style-has-border">
                                                <ul class="widget-menu-tab">
                                                    <li class="item-title active">
                                                        <span class="inner">Description</span>
                                                    </li>
                                                </ul>
                                                <div class="widget-content-tab">
                                                    <div class="widget-content-inner active">
                                                        <div style={{'textAlign' : 'left'}}>
                                                            {parse(this.state.addon_product.description)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        }
                    </Modal.Body>
                </Modal>

                <Offcanvas
                    className="coupon-canvas"
                    style={{ zIndex: "10000", width: "35vw" }}
                    placement={"end"}
                    show={this.state.show_coupons}
                    onHide={() => {
                        this.setState({'show_coupons' : false})
                    }}
                >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className='mb-0'>
                                <h6 className='mb-0'>Coupons</h6>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className='justify-content-center d-flex flex-row align-items-center'>
                                <div className='justify-content-start d-flex flex-column align-items-baseline w-100'>
                                    <input className="form-control" value={this.state.coupon_code} onChange={(event)=>{this.setState({'coupon_code' : event.target.value})}}  type="text" name="coupon_code" placeholder="Enter coupon code"/>
                                    <small style={{'color' : 'red'}}>{this.state.coupon_error}</small>
                                </div>
                                
                                <button type="button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center" onClick={()=>{this.checkCouponCode()}}>Apply</button>
                                
                            </div>
                            {this.state.coupons.map((coupon, index)=>{
                                return(

                                    <div className='card my-5' style={{'border' : '1px dashed #000000', cursor : "pointer"}}>
                                        <div className='w-100 card-header p-2 justify-content-between d-flex align-items-center flex-row'>
                                            <div className='flex-column d-flex'>
                                                <b onClick={()=>{this.setState({'show_coupon' : index})}}>{coupon.code}</b>
                                                <small>{coupon.description}</small>
                                            </div>
                                            
                                            <button type="button" onClick={()=>{this.setCoupon(coupon)}}  class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center">Apply</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </Offcanvas.Body>
                </Offcanvas>

                <div className="container padding-bottom-1x mb-1 mt-3">
                    <div className="row">
                        <div className="col-md-6 my-2">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Booking Info</h6>
                                    <div className="justify-content-between my-1 d-flex align-items-center">
                                        <strong>Service For</strong>
                                        {this.state.booking.service_type == 0 &&
                                            <p style={{'color' : '#24b263'}}>Badinton</p>
                                        }
                                        {this.state.booking.service_type == 1 &&
                                            <p style={{'color' : '#24b263'}}>Tennis</p>
                                        }
                                    </div>
                                    <div className="my-1 justify-content-between d-flex align-items-center">
                                        <strong>Slot Date</strong>
                                        <p style={{'color' : '#24b263'}}>{this.state.booking.slot_date}</p>
                                    </div>
                                    <div className="my-1 justify-content-between d-flex align-items-center">
                                        <strong>Slot Time</strong>
                                        {this.state.booking.slot_time == 0 &&
                                            <p style={{'color' : '#24b263'}}>11:00 AM - 2:00 PM</p>
                                        }
                                        {this.state.booking.slot_time == 1 &&
                                            <p style={{'color' : '#24b263'}}>7:00 PM - 9:00 PM</p>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 my-2">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Booking Address</h6>
                                    <div className="justify-content-between my-1 d-flex align-items-center">
                                        <strong>{this.state.booking.name}</strong>
                                    </div>
                                    <div className="my-1 justify-content-between d-flex align-items-center">
                                        <strong>{this.state.booking.address}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 my-2">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Racket Types</h6>
                                    <div style={{'overflow' :  'auto'}}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr style={{'border' : '1px solid #000000'}}>
                                                    <th style={{'fontWeight' : "bold"}}>Racket Type</th>
                                                    <th style={{'fontWeight' : "bold"}}>String Type</th>
                                                    <th style={{'fontWeight' : "bold"}}>Color</th>
                                                    <th style={{'fontWeight' : "bold"}}>Quantity</th>
                                                    <th style={{'fontWeight' : "bold"}}>Tension</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.booking.string_items.map((item)=>{
                                                return(
                                                    <tr style={{'border' : '1px solid #000000'}}>
                                                        <td>{item.racket_type}</td>
                                                        <td>{item.string_type_name}</td>
                                                        <td>{item.color}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.tension}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                        {this.state.addons.length > 0 &&
                            <div className='col-12 my-2'>
                                <div class="card">
                                    <div class="card-body">
                                        <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Addons</h6>
                                        <div class="table shopping-cart" style={{'overflow' :  'auto'}}>
                                            <div class="tf-page-cart-item">
                                                <form>
                                                    <table class="tf-table-page-cart">
                                                        <thead>
                                                            <tr>
                                                                <th>Product</th>
                                                                <th>Price</th>
                                                                <th>Color</th>
                                                                <th>Size</th>
                                                                <th>Quantity</th>
                                                                <th class="text-center">Discount</th>
                                                                <th class="text-center">Subtotal</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.addons.map((product, index)=>{
                                                                return(
                                                                    <tr class="tf-cart-item file-delete">
                                                                        <td class="tf-cart-item_product">
                                                                            <a href="product-detail.html" class="img-box">
                                                                                <img src={process.env.REACT_APP_MEDIA_URL +'/'+ product.image } alt="img-product"/>
                                                                            </a>
                                                                            <div class="cart-info">
                                                                                <a href="product-detail.html" class="cart-title link">{product.name}</a>
                                                                                <span class="remove-cart link remove" onClick={()=>{this.deleteAddons(index)}}>Remove</span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="tf-cart-item_price" cart-data-title="Price">
                                                                            <div class="cart-price">{product.price}</div>
                                                                        </td>
                                                                        <td class="tf-cart-item_price" cart-data-title="Price">
                                                                            <div class="cart-price">{product.color}</div>
                                                                        </td>
                                                                        <td class="tf-cart-item_price" cart-data-title="Price">
                                                                            <div class="cart-price">{product.size}</div>
                                                                        </td>
                                                                        <td class="tf-cart-item_quantity" cart-data-title="Quantity">
                                                                            <div class="cart-quantity flex-column">
                                                                                <div class="wg-quantity">
                                                                                    <span class="btn-quantity minus-btn" onClick={()=>{this.changeQuantity(-1, index, product.max_quantity)}}>
                                                                                        <svg class="d-inline-block" width="9" height="1" viewBox="0 0 9 1" fill="currentColor"><path d="M9 1H5.14286H3.85714H0V1.50201e-05H3.85714L5.14286 0L9 1.50201e-05V1Z"></path></svg>
                                                                                    </span>
                                                                                    <input type="text" name="number" value={product.quantity}/>
                                                                                    <span class="btn-quantity plus-btn" onClick={()=>{this.changeQuantity(1, index, product.max_quantity)}}>
                                                                                        <svg class="d-inline-block" width="9" height="9" viewBox="0 0 9 9" fill="currentColor"><path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z"></path></svg>
                                                                                    </span>
                                                                                </div>
                                                                                {(product.quantity > product.max_quantity && product.max_quantity != 0) &&
                                                                                    <small style={{'color' : 'red', marginTop:'10px', 'textAlign' : 'center'}}>Only {product.max_quantity} left</small>
                                                                                }
                                                                            
                                                                                {(product.max_quantity == 0) &&
                                                                                    <small style={{'color' : 'red', marginTop:'10px', 'textAlign' : 'center'}}>Out of stock</small>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td class="tf-cart-item_total" cart-data-title="Total">
                                                                            <div class="cart-total">{product.discount} %</div>
                                                                        </td>
                                                                        <td class="tf-cart-item_total" cart-data-title="Total">
                                                                            <div class="cart-total">₹ {(product.price - product.price * product.discount /100).toFixed(0)}</div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        

                        <div className="col-12">
                             <div className='card mt-2 py-3'>
                                <div className="card-body">
                                    <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Payment Mode</h6>
                                    <div className='justify-content-around d-flex align-items-center'>
                                        <div style={{'width' : '50%', 'textAlign' : 'center', 'display' : 'flex', 'flexDirection' : 'row'}}>
                                            <div class="custom-control custom-checkbox" style={{'textAlign' : 'center', 'justifyContent':'center', 'alignItems':'center', 'width':'100%'}}>
                                                <label style={{'width' : '50%', 'textAlign' : 'center'}}>Online</label>
                                                <input class="custom-control-input option" name="size" value={this.state.payment_mode} onChange={(e)=>{this.setPaymentMode(0)}} checked={this.state.payment_mode == 0}  type="radio" id="2Black"/>
                                            </div> 
                                            
                                        </div>

                                        <div style={{'width' : '50%', 'textAlign' : 'center', 'display' : 'flex', 'flexDirection' : 'row'}}>
                                            <div class="custom-control custom-checkbox" style={{'textAlign' : 'center', 'justifyContent':'center', 'alignItems':'center', 'width':'100%'}}>
                                                <label style={{'width' : '50%', 'textAlign' : 'center'}}>Cash on Delivery</label>
                                                <input class="custom-control-input option mx-5" name="size" value={this.state.payment_mode} onChange={(e)=>{this.setPaymentMode(1)}} checked={this.state.payment_mode == 1}  type="radio" id="2Black"/>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <div class="card mt-4">
                                    <div class="card-body">
                                        <div class="shopping-cart-footer">
                                                
                                            <div className="input-group my-2">
                                                <label className='w-100' style={{'textAlign' : "left", fontWeight : "bold", 'width' : '100%'}}>Refer Code</label>
                                                <div className='justify-content-center d-flex flex-row align-items-center w-100 mt-2'>
                                                    <input disabled={this.state.discount_type == 1} value={this.state.refer_code} onChange={(event)=>{this.setState({'refer_code' : event.target.value})}} className="form-control" type="text" name="refer_code" placeholder="Enter refer code"/>
                                                    {this.state.discount_type != 1 &&
                                                        <button type="button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center" onClick={()=>{this.applyReferCode()}}>Apply</button>
                                                    }
                                                    {this.state.discount_type == 1 &&
                                                        <button type="button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center" onClick={()=>{this.removeReferCode()}}>Remove</button>
                                                    }
                                                </div>
                                            </div>
                                            
                                            <>
                                                <div className="justify-content-center d-flex align-items-center">
                                                    <hr style={{'width' : '40%'}}></hr>
                                                    <b>OR</b>
                                                    <hr style={{'width' : '40%'}}></hr>
                                                </div>
                                                <div className="input-group my-2">
                                                    <label className='w-100' style={{'textAlign' : "left", fontWeight : "bold", 'width' : '100%'}}>Coupon</label>
                                                    <div className='justify-content-center d-flex flex-row align-items-center w-100 mt-2'>
                                                        <input disabled value={this.state.coupon.code ? this.state.coupon.code : ""} className="form-control" type="text" name="coupon_code" placeholder="Select coupon"/>
                                                        {this.state.discount_type != 2 &&
                                                            <button type="button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center" onClick={()=>{this.setState({'show_coupons' : true})}}>Select</button>
                                                        }
                                                        {this.state.discount_type == 2 &&
                                                            <button type="button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center" onClick={()=>{this.removeCouponCode()}}>Remove</button>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </>
                                                
                                            {this.state.product.id &&
                                                <>
                                                    <div className="justify-content-center d-flex align-items-center">
                                                        <hr style={{'width' : '40%'}}></hr>
                                                        <b>OR</b>
                                                        <hr style={{'width' : '40%'}}></hr>
                                                    </div>
                                                    <label className='w-100' style={{'textAlign' : "left", fontWeight : "bold", 'width' : '100%'}}>Free Product</label>
                                                    <div className='card m-2' style={{'border':'1px solid #EDEDED'}}>
                                                        <div className='card-body'>
                                                                
                                                            <div className='w-100 justify-content-start d-flex align-items-flex-start flex-row'>
                                                                <Link to={`/product/product-details/${this.state.product.id}`}  style={{'verticalAlign' : 'middle', color:'#FF6A00 !important'}} href="javascript::void(0)">
                                                                    <img style={{'width':'100px', 'height':'100px', 'objectFit':'contain'}} src={process.env.REACT_APP_MEDIA_URL + this.state.product.image } alt="Product"/>    
                                                                </Link>
                                                                <div className="w-100 justify-content-center d-flex align-items-baseline flex-column">
                                                                    <Link to={`/product/product-details/${this.state.product.id}`} class="product-info" style={{'verticalAlign' : 'middle', color:'#FF6A00 !important'}}>
                                                                        <p style={{'color' : '#FF6A00'}} className="product-title">{this.state.product.name}</p>
                                                                    </Link> 

                                                                    <div style={{'width' : '100%'}}  className='my-2 justify-content-start d-flex align-items-center flex-row flex-wrap'>
                                                                        {this.state.product.product_colors &&
                                                                            <div className='px-1 py-2 mx-1 my-1' style={{'background' : '#f3f5f6', 'width' : '40%'}}>
                                                                                <label style={{'fontSize' : '12px', 'fontWeight' : 'bold', 'textAlign' : 'left'}}>Color : </label>
                                                                                <select className="form-control" onChange={(event)=>{this.changeProductColor(event.target.value)}} value={this.state.product.color}>
                                                                                    <option value=""></option>
                                                                                    {this.state.product.product_colors.map((color)=>{
                                                                                        return(
                                                                                            <option value={color.color}>{color.color}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        }

                                                                        {this.state.product.product_sizes &&
                                                                            <div className='px-1 py-2 mx-1 my-1' style={{'background' : '#f3f5f6',  'width' : '40%'}}>
                                                                                <label style={{'fontSize' : '12px', 'fontWeight' : 'bold', 'textAlign' : 'left'}}>Size : </label>
                                                                                <select className="form-control" onChange={(event)=>{this.changeProductSize(event.target.value)}} value={this.state.product.size}>
                                                                                    <option value=""></option>
                                                                                    {this.state.product.product_sizes.map((size)=>{
                                                                                        return(
                                                                                            <option value={size.size}>{size.size}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        }
                                                                        
                                                                        {this.state.discount_type != 3 &&
                                                                            <button type="button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center" onClick={()=>{this.applyFreeProduct()}}>Apply</button>
                                                                        }
                                                                        {this.state.discount_type == 3 &&
                                                                            <button type="button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center" onClick={()=>{this.removeFreeProduct()}}>Remove</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='card mt-4'>
                                    <div className='card-body justify-content-end d-flex flex-column aling-items-end'>
                                        <h5 style={{'textAlign' : 'left', width:'100%', 'fontWeight' : "bold"}}>Order Bill</h5>
                                        <table className='table bill-table table-bordered w-100'>
                                            <tbody>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>String Price</th>
                                                    <td style={{'textAlign' : 'right'}}>₹ {this.state.string_price}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Addon Price</th>
                                                    <td style={{'textAlign' : 'right'}}>₹ {this.state.addon_price}</td>
                                                </tr>
                                                {this.state.discount_type == 1 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Referral Discount</th>
                                                        <td style={{'textAlign' : 'right'}}>-₹ {this.state.referral_discount.toFixed(0)}</td>
                                                    </tr>
                                                }
                                                {this.state.discount_type == 2 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Coupon Discount</th>
                                                        <td style={{'textAlign' : 'right'}}>-₹ {this.state.coupon_discount.toFixed(0)}</td>
                                                    </tr>
                                                }

                                                {this.state.discount_type == 3 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Free Tshirt</th>
                                                        <td style={{'textAlign' : 'right'}}>1</td>
                                                    </tr>
                                                }
                                                
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>To Pay</th>
                                                    <td style={{'textAlign' : 'right'}}>
                                                        {this.state.total_discount > 0 &&
                                                            <span style={{'fontWeight' : "bold", "textDecorationLine" : "line-through", 'marginRight' : '4%'}}>₹ {(this.state.total_price - this.state.referral_discount - this.state.coupon_discount).toFixed(0)}</span>
                                                        }
                                                        <span style={{'fontWeight' : "bold"}}> ₹ {this.state.string_price + this.state.addon_price - this.state.referral_discount - this.state.coupon_discount}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button onClick={()=>{this.verifyUser()}} id="continue__button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center mt-5" type="button"><span>Checkout</span></button>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.string_addons.length > 0 &&
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title d-flex justify-content-between d-flex align-items-center">
                                        <h2 className="h3">Addons</h2>
                                        <div className="right-area">
                                            <div  className="right_link" onClick={()=>{this.setState({'show_addon' : true})}}>View All 
                                                <i className="icon-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="tf-slideshow slider-home-4 slider-effect-fade position-relative mb-5">
                                <div dir="ltr" class="swiper tf-sw-slideshow" data-preview="4" data-tablet="1" data-mobile="1" data-centered="false" data-space="0" data-loop="true" data-auto-play="false" data-delay="2000" data-speed="1000">
                                    <Swiper slidesPerView={4} class="swiper-wrapper">
                                        {this.state.string_addons.slice(0, 6).map((product, index)=>{
                                            return(
                                                <SwiperSlide>
                                                    <div onClick={()=>{this.setState({'show_addon' : true})}} class="card-product style-4">
                                                        <div class="card-product-wrapper">
                                                            <div class="product-img">
                                                                <img class="lazyload img-product" data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt="image-product"/>
                                                                <img class="lazyload img-hover" data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt="image-product"/>
                                                            </div>
                                                            
                                                            <div data-bs-toggle="modal" class="btn-quick-add quick-add">View</div>
                                                        </div>
                                                        <div class="card-product-info">
                                                            <div  class="title link">{product.name}</div>
                                                            <span class="price">
                                                                {product.discount == 0 &&
                                                                    <>
                                                                        ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                                    </>
                                                                }
                                                                {product.discount > 0 &&
                                                                    <>
                                                                        <del>₹ {product.price}</del>
                                                                        <span className="bg-success mx-2 px-2" style={{'color' : '#FFFFFF',  borderRadius : 50}}>{product.discount} % OFF</span>
                                                                        <span>₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}</span>
                                                                    </>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    }
                
                    
                </div>
            </>
                
        )
    }


}


export default withRouter(StringingCheckout)