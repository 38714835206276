import React from 'react'


import Latest_Arrivals from './latest_arrivals';
import Banners from './banners';
import Popular_Products from './popular_products'
import Services from './services';


class Home extends React.Component {

    componentDidMount = async()=>{

    }


    constructor(props){
        super(props);
        this.state = {
            "address" : "",
            "latitude" : null,
            "longitude" : null,
        }
    }


    componentDidUpdate = (prevProps)=>{
        if(this.state.latitude != this.props.latitude || this.state.longitude != this.props.longitude){
            this.setState({'latitude' : prevProps.latitude, 'longitude' : prevProps.longitude})
        }
    }
    
    
    render() {
        return (
            <>
                <Banners/>
                <Services/>
                <Latest_Arrivals latitude={this.state.latitude} longitude={this.state.longitude}/>
                <Popular_Products latitude={this.state.latitude} longitude={this.state.longitude}/>
            </>
        )
    }
}

export default Home;