import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Api from '../Services/EcomApi';
import { toast } from 'react-toastify';
import withRouter from '../Services/WithRouter';
import parse from 'html-react-parser';

class Product extends React.Component {


    componentDidMount =async ()=>{
        let id = await this.props.params.id;
        if(id){
            this.getProductDetails(id);
        }
        else{

        }
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(this.props.params.id != this.state.product.id){
            this.getProductDetails(this.props.params.id);
        }
    }



    constructor(props){
        super(props);
        this.state={
            'product' : {
                'id' : "",
                'product_images' : [],
                'description' : "",
                'stylist_notes' : "",
                'product_sizes' : [],
                'product_colors' : []
            },
            'color' : "",
            'size' : ""
        }
        
    }



    getProductDetails = async(product_id)=>{
        window.scroll(0, 0);
        var params = {
            'product_id' : product_id 
        }
        var response = await Api.GetRequest(params, 'product');
        await response.product.product_images.push({'image' : response.product.image})
        if(response.status == 200){
            
            await this.setState({
                'product' : response.product
            });
        }
        else{
            toast.error("Product details doesn't exists.")
        }

    }



    addToCart = async()=>{
        var data = {
            'product_id' : this.state.product.id,
            'color' : this.state.color,
            'size' : this.state.size
        }
        if(this.state.product.product_colors.length > 0 && data.color == ""){
            toast.error("Please select color.")
        }
        else if(this.state.product.product_sizes.length > 0 && data.size == ""){
            toast.error("Please select size.")
        }
        else{
            var response = await Api.PostRequest(data, 'add-to-cart');
            if(response.status == 200){
                toast.success("Product added in your cart successfully.");
                this.props.fetchCart();
            }
            else{
                toast.error("Something went wrong. Please try again later.");
            }
        }
    }


    setColor = async(color)=>{
        if(this.state.color != color){
            await this.setState({
                'color' : color,
                'size' : "",
            });
            await this.getSizes();
        }      
    }


    getSizes = async()=>{
        var params = {
            'product_id' : this.state.product.id,
            'color' : this.state.color
        };
        var response = await Api.GetRequest(params, 'sizes')
        if(response.status == 200){
            await this.setState({ product:{
                ...this.state.product,
                'product_sizes': response.sizes
            }});
        }

    }



    setSize = async(size)=>{
        this.setState({'size' : size});
    }


    render() {
        return (
            <>
                <section class="flat-spacing-4 pt_0">
                    <div class="tf-main-product section-image-zoom">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="tf-product-media-wrap sticky-top">
                                        <div class="thumbs-slider">
                                            
                                            <div dir="ltr" class="swiper tf-product-media-main" id="gallery-swiper-started">
                                                <Swiper class="swiper-wrapper">
                                                    {this.state.product.product_images.map((product)=>{
                                                        return(
                                                            <SwiperSlide class="swiper-slide" data-color="beige">
                                                                <a href="javascript:;" target="_blank" class="item" data-pswp-width="770px" data-pswp-height="1075px">
                                                                    <img class="tf-image-zoom lazyload" data-zoom={process.env.REACT_APP_MEDIA_URL + product.image} data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt=""/>
                                                                </a>
                                                            </SwiperSlide>
                                                        )
                                                    })}
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="tf-product-info-wrap position-relative">
                                        <div class="tf-zoom-main"></div>
                                        <div class="tf-product-info-list other-image-zoom">
                                            <div class="tf-product-info-title">
                                                <h5>{this.state.product.name}</h5>
                                            </div>
                                            <div class="tf-product-info-badges">
                                                <div class="badges">Best seller</div>
                                                <div class="product-status-content">
                                                    <i class="icon-lightning"></i>
                                                    <p class="fw-6">Selling fast! many people have  this in their carts.</p>
                                                </div>
                                            </div>
                                            <div class="tf-product-info-price">
                                                {this.state.product.discount == 0 &&
                                                    <>
                                                        <div class="price-on-sale">₹ {(this.state.product.price - (this.state.product.price * this.state.product.discount)/100).toFixed(0)}</div>
                                                    </>
                                                }
                                                {this.state.product.discount > 0 &&
                                                    <>
                                                        <div class="price-on-sale">₹ {(this.state.product.price - (this.state.product.price * this.state.product.discount)/100).toFixed(0)}</div>
                                                        <div class="compare-at-price">₹ {this.state.product.price}</div>
                                                        <div class="badges-on-sale"><span>{this.state.product.discount}</span>% OFF</div>
                                                    </>
                                                }
                                            </div>
                                            <div class="tf-product-info-liveview">
                                                <p class="fw-6">Many people are viewing this right now</p>
                                            </div>
                                            
                                            
                                            <div class="tf-product-info-variant-picker">
                                                {this.state.product.product_colors.length > 0 &&
                                                    <div class="variant-picker-item">
                                                        <div class="quantity-title fw-6" style={{'textAlign' : "left"}}>Color</div>
                                                        <div class="variant-picker-values mt-2">
                                                            <select class="tf-select" value={this.state.color} onChange={(event)=>this.setColor(event.target.value)} style={{'color' : '#000000', background : '#ffffff'}}>
                                                                <option value="">Select Color</option>
                                                                {this.state.product.product_colors.map((color)=>{
                                                                    return(
                                                                        <option value={color}>{color.toUpperCase()}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                                
                                                {this.state.product.product_sizes.length > 0 &&
                                                    <div class="variant-picker-item">
                                                        <div class="quantity-title fw-6" style={{'textAlign' : "left"}}>Size</div>
                                                        <div class="variant-picker-values">
                                                            <select class="tf-select" value={this.state.size} onChange={(event)=>this.setSize(event.target.value)} style={{'color' : '#000000', background : '#ffffff'}}>
                                                                <option value="">Select Size</option>
                                                                {this.state.product.product_sizes.map((size)=>{
                                                                    return(
                                                                        <option value={size}>{size.toUpperCase()}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            
                                            <div class="tf-product-info-buy-button">
                                                <a href="javascript:void(0);" onClick={()=>{this.addToCart()}} class="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn btn-add-to-cart w-100"><span>Add to cart</span></a>   
                                            </div>
                                           
                                            <div class="tf-product-info-delivery-return">
                                                <div class="row">
                                                    <div class="col-xl-6 col-12">
                                                        <div class="tf-product-delivery">
                                                            <div class="icon">
                                                                <i class="icon-delivery-time"></i>
                                                            </div>
                                                            <p>Estimate delivery times: <span class="fw-7">3-7 days</span></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 col-12">
                                                        <div class="tf-product-delivery mb-0">
                                                            <div class="icon">
                                                                <i class="icon-return-order"></i>
                                                            </div>
                                                            <p>Return within <span class="fw-7">30 days</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </section>

                <section class="flat-spacing-17 pt_0">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="widget-tabs style-has-border">
                                    <ul class="widget-menu-tab">
                                        <li class="item-title active">
                                            <span class="inner">Description</span>
                                        </li>
                                    </ul>
                                    <div class="widget-content-tab">
                                        <div class="widget-content-inner active">
                                            <div style={{'textAlign' : 'left'}}>
                                                {parse(this.state.product.description)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>        
        )
    }
}

export default withRouter(Product);


