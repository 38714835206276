import React from "react";
import Modal from 'react-bootstrap/Modal';
import Api from "../Services/EcomApi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from 'react-bootstrap/Switch';

class NewAddress extends React.Component{


    constructor(props){
        super(props);
        this.state={
            'name' : "",
            'email' : "",
            'phone' : "",
            'address' : "",
            'city' : "",
            'state' : "",
            'zipcode' : "",
            'is_default' : "",

            'name_error' : "",
            'email_error' : "",
            'phone_error' : "",
            'address_error' : "",
            'city_error' : "",
            'state_error' : "",
            'zipcode_error' : "",
        }
    }


    resetAddress = async()=>{
        this.setState({
            'name' : "",
            'email' : "",
            'phone' : "",
            'address' : "",
            'city' : "",
            'state' : "",
            'zipcode' : "",
            'is_default' : false,

            'name_error' : "",
            'email_error' : "",
            'phone_error' : "",
            'address_error' : "",
            'city_error' : "",
            'state_error' : "",
            'zipcode_error' : "",
        });
    }


    
    validateAddress = async()=>{
        var status = true;
        var data = {
            'name' : this.state.name.trim(),
            'email' : this.state.email.trim(),
            'phone' :this.state.phone.trim(),
            'address' :this.state.address.trim(),
            'city' :this.state.city.trim(),
            'state' :this.state.state.trim(),
            'zipcode' :this.state.zipcode.trim(),
            'is_default' : this.state.is_default,
        }

        if(data.name.length == 0){
            status = false;
            this.setState({'name_error' : "Please enter name"});
        }
        else{
            this.setState({'name_error' : ""});
        }
        console.log(data)
        
        if(data.address.length == 0){
            status = false;
            this.setState({'address_error' : "Please enter address"});
        }
        else{
            this.setState({'address_error' : ""});
        }

        if(data.city.length == 0){
            status = false;
            this.setState({'city_error' : "Please enter city name"});
        }
        else{
            this.setState({'city_error' : ""});
        }

        if(data.state.length == 0){
            status = false;
            this.setState({'state_error' : "Please enter state name"});
        }
        else{
            this.setState({'state_error' : ""});
        }

        if(data.zipcode.length != 6){
            status = false;
            this.setState({'zipcode_error' : "Please enter valid zipcode"});
        }
        else{
            this.setState({'zipcode_error' : ""});
        }


        if(data.phone == "0000000000") {
            status = false;
            this.setState({'phone_error' : "Please enter valid phone number."});
        }
        else if(data.phone[0] == "0") {
            status = false;
            this.setState({'phone_error' : "Please enter valid phone number."});
        }
        else{
            var regx = new RegExp("^[0-9]{10}$");
            var res = await regx.test(data.phone);
            if (res) {
                this.setState({'phone_error' : ""});
            }
            else{
                status = false
                this.setState({'phone_error' : "Please enter valid phone number."});
            }
        }


        var emailregx = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/;
        var res = await emailregx.test(data.email);
        if(res) {
            this.setState({'email_error' : ""});
        }
        else {
            status = false
            this.setState({'email_error' : "Please enter valid email id."});
        }



        if(status == true){
            this.saveAddress(data)
        }
    }

    saveAddress = async(data)=>{
        if(data.is_default == true){
            data.is_default = 1
        }
        else{
            data.is_default = 0
        }
        var response = await Api.PostRequest(data, 'addresses');
        this.handleClose();
        if(response.status == 200){
            this.resetAddress()
            toast.success("Address saved successfully.");
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }


    handleClose = async()=>{
        this.resetAddress();
        this.props.handleNewAddress();
    }



    render(){
        return(
            <Offcanvas
                style={{ zIndex: "10000"}}
                placement={"end"}
                show={this.props.show_new_address}
                onHide={() => {
                    this.handleClose()
                }}
            >
                
                <Offcanvas.Body>  
                    <div className="w-100 justify-content-between d-flex align-items-center">
                        <h6 className='mb-0'>New Address</h6>
                        <i class="icon-x" style={{'fontSize' : '25px'}} onClick={()=>{this.handleClose()}}></i>
                    </div>   
                    <form id="newAddress"className='mt-5'>    
                        <div class="row">
                            <div class="col-sm-6 my-2">
                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                    <label for="checkout-fn">Name *</label>
                                    <input class="form-control" value={this.state.name} onChange={(event)=>{this.setState({'name' : event.target.value})}} name="bill_name" type="text" id="checkout-fn" required/>
                                    {this.state.name_error &&
                                        <small className="text-danger">{this.state.name_error}</small>
                                    }
                                </div>
                            </div>

                            <div class="col-sm-6 my-2">
                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                    <label for="checkout-phone">Phone Number *</label>
                                    <input class="form-control" value={this.state.phone} onChange={(event)=>{this.setState({'phone' : event.target.value})}} name="bill_phone" type="text" id="checkout-phone" required/>
                                    {this.state.phone_error &&
                                        <small className="text-danger">{this.state.phone_error}</small>
                                    }
                                </div>
                            </div>
                            
                        </div>
                        
                        <div class="row">

                            <div class="col-sm-12 my-2">
                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                    <label for="checkout_email_billing">E-mail *</label>
                                    <input class="form-control" value={this.state.email} onChange={(event)=>{this.setState({'email' : event.target.value})}} name="bill_email" type="email" id="checkout_email_billing" required/>
                                    {this.state.email_error &&
                                        <small className="text-danger">{this.state.email_error}</small>
                                    }
                                </div>
                            </div>
                            
                            
                        </div>
                        <div class="row">
                            <div class="col-sm-12 my-2">
                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                <label for="checkout-company">Address *</label>
                                <input class="form-control" value={this.state.address} onChange={(event)=>{this.setState({'address' : event.target.value})}} name="bill_company" type="text" id="checkout-company" required/>
                                {this.state.address_error &&
                                    <small className="text-danger">{this.state.address_error}</small>
                                }
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-6 my-2">
                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                    <label for="checkout-city">State *</label>
                                    <input class="form-control" value={this.state.state} onChange={(event)=>{this.setState({'state' : event.target.value})}} name="bill_state" type="text" id="checkout-state" required/>
                                    {this.state.state_error &&
                                        <small className="text-danger">{this.state.state_error}</small>
                                    }
                                </div>
                            </div>
                            <div class="col-sm-6 my-2">
                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                    <label for="checkout-city">City *</label>
                                    <input class="form-control" value={this.state.city} onChange={(event)=>{this.setState({'city' : event.target.value})}} name="bill_city" type="text" id="checkout-city" required/>
                                    {this.state.city_error &&
                                        <small className="text-danger">{this.state.city_error}</small>
                                    }
                                </div>
                            </div>
                        </div>     

                        <div class="row">
                            <div class="col-sm-6 my-2">
                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                    <label for="checkout-zip">Zip Code *</label>
                                    <input class="form-control" value={this.state.zipcode} onChange={(event)=>{this.setState({'zipcode' : event.target.value})}} name="bill_zip" type="text" id="checkout-zip" required/>
                                    {this.state.zipcode_error &&
                                        <small className="text-danger">{this.state.zipcode_error}</small>
                                    }
                                </div>
                            </div>

                            <div class="col-sm-6 my-2">
                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                    <label for="checkout-zip"></label>
                                    <Switch
                                        type="switch"
                                        id="custom-switch"
                                        label="Set as default"
                                        checked={this.state.is_default == true}
                                        value =  {this.state.is_default}
                                        onChange={(event)=>{this.setState({'is_default' : event.target.checked})}}
                                        className="my-4"
                                    />
                                </div>
                            </div>
                        </div>  


                        <div class="d-flex justify-content-center paddin-top-1x mt-4">
                            <button onClick={()=>{this.validateAddress()}} class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center w-100">Save</button>
                        </div>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>            
        )
    }

}



export default NewAddress