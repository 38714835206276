import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import Api from './components/Services/EcomApi';

import Header from './components/Header/header';
import Footer from './components/Footer/footer';
import Home from './components/Home/home';
import Shop from './components/Shop/products';
import Product from './components/Shop/product';
import Cart from './components/Shop/cart';
import Checkout from './components/Shop/checkout';
import Profile from './components/Profile/profile';
import BookService from './components/Stringing/book_service';
import StrigingCheckout from './components/Stringing/stringing_checkout';
import Stadiums from './components/Stadiums/stadiums';
import Stadium from './components/Stadiums/stadium';
import Order from './components/Profile/order';
import Service from './components/Profile/service';

function App() {

  const [cart, setCart] = useState(0)
  const [is_logged_in, setLoggedIn] = useState(false)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [address, setAddress] = useState(null)

  useEffect(() => {
    fetchCart()
    checkUserLogin()
  }, [cart.length, is_logged_in, latitude, longitude]);

  const fetchCart = async()=>{
    var response = await Api.GetRequest({}, 'cart');
    if(response.status == 200){
      await setCart(response.cart);
    }
    else{
      setCart(0);
    }
  }


  const checkUserLogin = async()=>{
    var response = await Api.GetRequest({}, 'verify-user');
    if(response.status == 200){
      setLoggedIn(true)
    }
    else{
      setLoggedIn(false)
    }
  }


  const setUserLogIn = async(status)=>{
    setLoggedIn(status)
  }

  const setLocation = async(latitude, longitude, address)=>{
    await setLatitude(latitude);
    await setLongitude(longitude);
    await setAddress(address);
  }
  
  return (
    <Router>
      <div className="App" id="App">
        <Header setLocation={setLocation} fetchCart={fetchCart} cart={cart} is_logged_in={is_logged_in} setUserLogIn={setUserLogIn}/>
        <Routes>
          <Route path="/" exact element={<Home latitude={latitude} longitude={longitude} address={address}/>}/>
          <Route path="/home" exact element={<Home latitude={latitude} longitude={longitude} address={address}/>}/>
          <Route path="/shop/products" exact element={<Shop latitude={latitude} longitude={longitude}/>}/>
          <Route path="/shop/product/:id" exact element={<Product fetchCart={fetchCart}/>}/>
          <Route path="/shop/cart" exact element={<Cart fetchCart={fetchCart}/>}/>
          <Route path="/shop/checkout" exact element={<Checkout is_logged_in={is_logged_in} fetchCart={fetchCart}/>}/>
          <Route path="/profile" exact element={<Profile checkUserLogin={checkUserLogin}/>}/>
          <Route path="/stringing/book-service/:service_type" exact element={<BookService latitude={latitude} longitude={longitude} address={address}/>}/>
          <Route path="/stringing/checkout" exact element={<StrigingCheckout setUserLogIn={setUserLogIn}/>}></Route>
          <Route path="/stadiums" exact element={<Stadiums latitude={latitude} longitude={longitude} address={address}/>}></Route>
          <Route path="/stadiums/stadium/:stadium_id" exact element={<Stadium latitude={latitude} longitude={longitude}/>}></Route>
          <Route path="/profile/order/:order_id" exact element={<Order/>}></Route>
          <Route path="/profile/service/:service_id" exact element={<Service/>}></Route>
        </Routes> 
         <Footer/> 
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;