import React from 'react';
import * as ReactDOM from 'react-dom';
import Api from '../Services/StringApi';
import withRouter from '../Services/WithRouter';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Service extends React.Component{


    componentDidMount = async()=>{
        let service_id = await this.props.params.service_id
        if(service_id){
            this.getBooking(service_id);
        }
        
    }


    

    constructor(props){
        super(props);
        this.state = {
            "booking" : {
                'booking_string_items' : [],
                'booking_string_addons' : [],
                'product' : {},
            },
        }
    }


    getBooking = async(booking_id)=>{
        var params = {
            'id' : booking_id
        }
        var response = await Api.GetRequest(params, 'string/booking');
        if(response.status == 200){
            if(response.booking.product.length > 0){
                response.booking.product = JSON.parse(response.booking.product)
            }
            else{
                response.booking.product = {}
            }
            
            this.setState({"booking": response.booking});

        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }


    render() {
        return(
            <>  
                <div class="flat-spacing-6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 my-2">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Booking Info</h6>
                                        <div className="justify-content-between my-1 d-flex align-items-center">
                                            <strong>Service For</strong>
                                            {this.state.booking.service_type == 0 &&
                                                <p style={{'color' : '#24b263'}}>Badinton</p>
                                            }
                                            {this.state.booking.service_type == 1 &&
                                                <p style={{'color' : '#24b263'}}>Tennis</p>
                                            }
                                        </div>
                                        <div className="my-1 justify-content-between d-flex align-items-center">
                                            <strong>Slot Date</strong>
                                            <p style={{'color' : '#24b263'}}>{this.state.booking.slot_date}</p>
                                        </div>
                                        <div className="my-1 justify-content-between d-flex align-items-center">
                                            <strong>Slot Time</strong>
                                            {this.state.booking.slot_time == 0 &&
                                                <p style={{'color' : '#24b263'}}>11:00 AM - 2:00 PM</p>
                                            }
                                            {this.state.booking.slot_time == 1 &&
                                                <p style={{'color' : '#24b263'}}>7:00 PM - 9:00 PM</p>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 my-2">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Booking Address</h6>
                                        <div className="justify-content-between my-1 d-flex align-items-center">
                                            <strong>{this.state.booking.name}</strong>
                                        </div>
                                        <div className="my-1 justify-content-between d-flex align-items-center">
                                            <strong>{this.state.booking.address}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-2">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Racket Types</h6>
                                        <div className='my-account-content account-order'>
                                            <div class="wrap-account-order">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="fw-6">Racket Type</th>
                                                            <th className="fw-6">String Type</th>
                                                            <th className="fw-6">Color</th>
                                                            <th className="fw-6">Quantity</th>
                                                            <th className="fw-6">Tension</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.booking.booking_string_items.map((item)=>{
                                                            return(
                                                                <tr className="tf-order-item">
                                                                    <td>{item.racket_type}</td>
                                                                    <td>{item.string_type_name}</td>
                                                                    <td>{item.color}</td>
                                                                    <td>{item.quantity}</td>
                                                                    <td>{item.tension}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            {this.state.booking.booking_string_addons.length > 0 &&
                                <div className='col-12 my-2'>
                                    <div class="card">
                                        <div class="card-body">
                                            <h6 className="w-100 mb-4" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}>Addons</h6>
                                            <div className='my-account-content account-order'>
                                                <div class="wrap-account-order">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th className="fw-6">Product</th>
                                                                <th className="fw-6">Price</th>
                                                                <th className="fw-6">Color</th>
                                                                <th className="fw-6">Size</th>
                                                                <th className="fw-6">Quantity</th>
                                                                <th className="fw-6">Discount</th>
                                                                <th className="fw-6">Subtotal</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.state.booking.booking_string_addons.map((product, index)=>{
                                                                return(
                                                                    <tr className="tf-order-item" key={index}>
                                                                        <td>
                                                                            <div class="product-item">
                                                                                <div style={{'verticalAlign' : 'middle'}}>
                                                                                    <img style={{width:'100px', height:'100px', objectFit:'contain'}} src={process.env.REACT_APP_MEDIA_URL +'/'+ product.image } alt="Product"/>    
                                                                                </div>
                                                                                <div class="product-info" style={{'verticalAlign' : 'middle'}}>
                                                                                    <h4 class="product-title" style={{'textAlign' : 'left', marginLeft:'10px'}}>{product.name}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-center text-lg">₹ {product.price}</td>

                                                                        <td class="text-center text-lg">{product.color}</td>

                                                                        <td class="text-center text-lg">{product.size}</td>
                                                                        <td class="text-center text-lg">{product.quantity}</td>
                                                                        <td class="text-center text-lg">{product.discount} %</td>
                                                                        <td class="text-center text-lg">₹ {(product.price - product.price * product.discount /100).toFixed(0)}</td>

                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                            <div className='col-md-6'>
                                <div class="card mt-4">
                                    <div className='card-body justify-content-start d-flex flex-column aling-items-start'>
                                        <h6 className='mb-4' style={{'textAlign' : 'left', width:'100%', 'fontWeight' : "bold"}}>Payment Info</h6>
                                        <table className='table bill-table table-bordered w-100'>
                                            <tbody>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Payment Mode</th>
                                                    <td style={{'textAlign' : 'right'}}>{this.state.booking.payment_mode == 0 ? "Online" : "Offline"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Payment Status</th>
                                                    <td style={{'textAlign' : 'right'}}>{this.state.booking.payment_status == 0 ? "Pending" : "Completed"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='col-md-6'>
                                <div className='card mt-4'>
                                    <div className='card-body justify-content-end d-flex flex-column aling-items-end'>
                                        <h6 className='mb-4' style={{'textAlign' : 'left', width:'100%', 'fontWeight' : "bold"}}>Order Bill</h6>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>String Price</th>
                                                    <td style={{'textAlign' : 'right'}}>₹ {this.state.booking.string_amount}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Addon Price</th>
                                                    <td style={{'textAlign' : 'right'}}>₹ {this.state.booking.addon_amount}</td>
                                                </tr>
                                                {this.state.booking.referral_discount > 0 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Referral Discount</th>
                                                        <td style={{'textAlign' : 'right'}}>-₹ {this.state.booking.referral_discount.toFixed(0)}</td>
                                                    </tr>
                                                }
                                                {this.state.booking.coupon_discount > 0 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Coupon Discount</th>
                                                        <td style={{'textAlign' : 'right'}}>-₹ {this.state.booking.coupon_discount.toFixed(0)}</td>
                                                    </tr>
                                                }

                                                {Object.keys(this.state.booking.product).length > 0 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Free Tshirt</th>
                                                        <td style={{'textAlign' : 'right'}}>1</td>
                                                    </tr>
                                                }
                                                
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Total Price</th>
                                                    <td style={{'textAlign' : 'right'}}>
                                                        <span style={{'fontWeight' : "bold"}}> ₹ {(this.state.booking.string_amount) + this.state.booking.addon_amount - this.state.booking.referral_discount - this.state.booking.coupon_discount}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



export default withRouter(Service);