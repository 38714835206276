import React from "react";
import Modal from 'react-bootstrap/Modal';
import Api from "../Services/EcomApi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Addresses extends React.Component{


    constructor(props){
        super(props);
        this.state={
            'addresses' : [],
            'address' : {}
        }
    }


    componentDidMount = async()=>{
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(this.props.selected_delivery_address != prevProps.selected_delivery_address){
            this.setState({'selected_delivery_address' : this.props.selected_delivery_address});
        }
    }


    selectAddress = async(address)=>{
        await this.setState({'address' : address});
        this.handleClose();
    }


    handleClose = async()=>{
        this.props.handleAddress(this.state.address)
    }


    addNewAddress = async()=>{
        this.props.addNewAddress()
    }

    updateDeliveryAddress = async(address_id)=>{
        var data = {
            "address_id" : address_id
        }

        var response = await Api.PostRequest(data, 'delivery-address');
        if(response.status == 200){
            toast.success("Delivery status updated successfully.");
            this.props.getAddresses();
        }
        else{
            toast.error(response.message)
        }
    }


    deleteAddress = async(address_id)=>{
        var data = {
            'id' : address_id
        }
        var response = await Api.DeleteRequest('address/'+address_id);
        if(response.status == 200){
            this.props.getAddresses();
            toast.success("Address deleted successfully.");
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }


    render(){
        return(
            <Offcanvas
                style={{ zIndex: "10000"}}
                placement={"end"}
                show={this.props.show_addresses}
                onHide={() => {
                    this.handleClose()
                }}

            >
                
                <Offcanvas.Body>  
                    <div className="w-100 justify-content-between d-flex align-items-center">
                        <h6 className='mb-0'>Addresses</h6>
                        <i class="icon-x" style={{'fontSize' : '25px'}} onClick={()=>{this.handleClose()}}></i>
                    </div>   
                    
                    {this.props.addresses.length > 0 &&
                        <>
                            {this.props.addresses.map((address)=>{
                                return(
                                    <div onClick={()=>{this.setState({"selected_delivery_address" : address.id})}} style={{'border' : '1px dashed #000000', cursor : "pointer", 'alignItems' : 'baseline', flexDirection : 'row'}} className="card w-100 my-2 px-3 py-2" >
                                        <div className="justify-content-center d-flex align-items-center flex-row">
                                            <div class="custom-control custom-radio" style={{'textAlign' : 'left', cursor:'pointer'}}>
                                                <input class="custom-control-input option" style={{'cursor' : 'pointer'}} name="size" onChange={(e)=>{this.setState({"selected_delivery_address" : address.id})}}  type="radio" checked={this.state.selected_delivery_address == address.id} />
                                            </div>  
                                        </div>
                                        <div className="mx-3" style={{'width' : '100%'}}>
                                            <p className="mb-0">{address.name}</p>
                                            <p className="mb-0">{address.phone}</p>
                                            <p className="mb-0">{address.address}</p>
                                            <p className="mb-0">{address.city}, {address.state}, {address.zipcode}</p>

                                            {address.id ==  this.state.selected_delivery_address &&
                                                <div className="justify-content-start d-flex align-items-center mt-3" style={{'width' : '100%'}}>
                                                    {address.is_delivery_address == 1 &&
                                                        <button disabled class="btn btn-primary btn-sm" type="button"><span>DELIVERING HERE</span></button>
                                                    }
                                                    {address.is_delivery_address == 0 &&
                                                        <button  onClick={()=>{this.updateDeliveryAddress(address.id)}} class="btn btn-primary btn-sm" type="button"><span>DELIVER HERE</span></button>
                                                    }
                                                    <div type="button" onClick={()=>{this.props.editAddress(address)}} className="px-3 py-1 mx-3" style={{'border' : '1px solid #FF6A00', 'backgroundColor' : '#FFFFFF', 'color' : '#FF6A00'}} >Edit</div>
                                                    <div type="button"  onClick={()=>{this.deleteAddress(address.id)}}  className="px-3 py-1 mx-3" style={{'border' : '1px solid #FF6A00', 'backgroundColor' : '#FFFFFF', 'color' : '#FF6A00'}} >
                                                        <i style={{color : "#000000"}} className="fa fa-trash"></i>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                    {this.props.addresses.length == 0 &&
                        <img src="/assets/images/no_address.png" alt="LOGO" style={{"width" : "100%"}}/>
                    }
                    
                    
                    <div style={{'position' : 'sticky', bottom : 0}} className="w-100 justify-content-between d-flex align-items-center">
                        <button onClick={()=>{this.addNewAddress()}} class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center w-100">Add Address</button>
                    </div>   
                </Offcanvas.Body>
            </Offcanvas>            
        )
    }

}



export default Addresses