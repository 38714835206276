import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import StringApi from '../Services/StringApi';



class Banners extends React.Component {

    componentDidMount = async()=>{
        this.getBanners();
    }


    constructor(props){
        super(props);
        this.state = {
            'banners' : []
        }
    }

    getBanners = async()=>{
        var response = await StringApi.GetRequest({}, 'banners');
        if(response.status == 200){
            this.setState({'banners' : response.banners});
        }
        else{
            // await toast.success("banner added sucessfully");
        }
    }

    render() {
        return (
            <>
                <div class="tf-slideshow slider-home-4 slider-effect-fade position-relative">
                    <div dir="ltr" class="swiper tf-sw-slideshow" data-preview="1" data-tablet="1" data-mobile="1" data-centered="false" data-space="0" data-loop="true" data-auto-play="false" data-delay="2000" data-speed="1000">
                        <Swiper modules={[Autoplay]} autoplay={{delay: 2000, disableOnInteraction: false, }} class="swiper-wrapper">
                            {this.state.banners.map((banner)=>{
                                return(
                                    <SwiperSlide>
                                        <div class="wrap-slider">
                                            <img src={process.env.REACT_APP_MEDIA_URL + banner.image} alt="fashion-slideshow"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div class="wrap-pagination sw-absolute-3">
                        <div class="sw-dots style-2 sw-pagination-slider"></div>
                    </div>
                </div> 
            </>        
        )
    }
}

export default Banners;


