import React from 'react'
import 'swiper/css';
import { Link } from 'react-router-dom';
import EcomApi from '../Services/EcomApi';


class Popular_Products extends React.Component {


    componentDidMount = async()=>{
        await this.getLatestArrivals();
    }



    getLatestArrivals = async()=>{
        if(this.state.latitude || this.state.longitude){
            var data = {
                'latitude' : this.state.latitude,
                'longitude' : this.state.longitude
            }
            var response = await EcomApi.GetRequest(data, "products/popular");
            if(response.status == 200){
                console.log("ddddd")
                console.log(response.products)
                this.setState({'latest_arrivals': response.products});
            }
            else{
    
            }
        }
    }


    constructor(props){
        super(props);
        this.state = {
            'latest_arrivals': [],
            'latitude' : null,
            'longitude' : null
        }
    }

     componentDidUpdate = (prevProps)=>{
        console.log(prevProps)
        if(this.state.latitude != prevProps.latitude || this.state.longitude != prevProps.longitude){

            this.setState({'latitude' : prevProps.latitude, 'longitude' : prevProps.longitude}, ()=>{
                this.getLatestArrivals();
            })
        }
    }


    render() {
        return (
            <>
                <section class="flat-spacing-6">
                    <div class="container">
                        <div class="flat-title mb_1 gap-14">
                            <span class="title wow fadeInUp" data-wow-delay="0s">Popular Products</span>
                            <p class="sub-title wow fadeInUp" data-wow-delay="0s">Shop the Latest Styles: Stay ahead of the curve with our newest arrivals</p>
                        </div>
                        <div class="grid-layout loadmore-item" data-grid="grid-4">
                        
                            {this.state.latest_arrivals.slice(0, 8).map((product, index)=>{
                                return(
                                    <>
                                        <div class="card-product style-4">
                                            <div class="card-product-wrapper">
                                                <Link to={`/shop/product/${product.id}`} class="product-img">
                                                    <img class="lazyload img-product" data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt="image-product"/>
                                                    <img class="lazyload img-hover" data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt="image-product"/>
                                                </Link>
                                                
                                                <Link to={`/shop/product/${product.id}`} data-bs-toggle="modal" class="btn-quick-add quick-add">View</Link>
                                            </div>
                                            <div class="card-product-info">
                                                <Link to={`/shop/product/${product.id}`} class="title link">{product.name}</Link>
                                                <span class="price">
                                                    {product.discount == 0 &&
                                                        <>
                                                            ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                        </>
                                                    }
                                                    {product.discount > 0 &&
                                                        <>
                                                            <del>₹ {product.price}</del>
                                                            <span className="bg-success mx-2 px-2" style={{'color' : '#FFFFFF',  borderRadius : 50}}>{product.discount} % OFF</span>
                                                            <span>₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}</span>
                                                        </>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </section>
            </>        
        )
    }
}

export default Popular_Products;