import React, { Component } from "react";
import withRouter from '../Services/WithRouter';
import Api from '../Services/StadiumApi';
import { Link } from "react-router-dom";

class Stadiums extends Component{


    componentDidMount = async()=>{
        await this.getStadiums();
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(prevProps.latitude != this.state.latitude || prevProps.longitude != this.state.longitude){
            await this.setState({'latitude' : prevProps.latitude, 'longitude' : prevProps.longitude}, ()=>{
                this.getStadiums();
            });  
        }
    }



    constructor(props){
        super(props);
        this.state={
            "stadiums" : [],
            "latitude" : "",
            "longitude" : ""
        }
    }

    getStadiums = async () => {
        var params = {
            'latitude' : this.state.latitude,
            'longitude' : this.state.longitude
        }
        var response = await Api.GetRequest(params, 'stadiums');
        if (response.status == 200) {
            await this.setState({'stadiums': response.stadiums});
        }
    }


    showStadiumDetails = async(stadium_id)=>{
        this.props.navigate('/stadiums/stadium/'+stadium_id);
    }

   
    render(){
        return(
            <>
                <div className="container padding-bottom-3x mb-1 mt-5">
                    <div class="wrapper-control-shop">
                        <div class="meta-filter-shop"></div>
                        <div class="grid-layout wrapper-shop" data-grid="grid-4">
                            {this.state.stadiums.length > 0 &&
                                <>
                                    {this.state.stadiums.map((stadium)=>{
                                        return(

                                            <div class="card-product style-4">
                                                <div class="card-product-wrapper">
                                                    <Link to={`/stadiums/stadium/${stadium.id}`} class="product-img">
                                                        <img class="lazyload img-product" data-src={process.env.REACT_APP_MEDIA_URL + stadium.image} src={process.env.REACT_APP_MEDIA_URL + stadium.image} alt="image-product"/>
                                                        <img class="lazyload img-hover" data-src={process.env.REACT_APP_MEDIA_URL + stadium.image} src={process.env.REACT_APP_MEDIA_URL + stadium.image} alt="image-product"/>
                                                    </Link>
                                                    
                                                    <Link to={`/stadiums/stadium/${stadium.id}`} data-bs-toggle="modal" class="btn-quick-add quick-add">View</Link>
                                                </div>
                                                <div class="card-product-info">
                                                    <Link to={`/stadiums/stadium/${stadium.id}`} class="title link">{stadium.name}</Link>
                                                    <span class="price">₹ {(stadium.price).toFixed(0)}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            }
                            
                            {(this.state.stadiums.length == 0) &&
                                <div className="col-12">
                                    <div className="product-card ">
                                        <div style={{'fontWeight' : 'bold', fontSize : "large"}} className="my-3">No Stadium Found</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

}


export default  withRouter(Stadiums);