// / * eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

class Services extends React.Component {


    constructor(props){
        super(props);
        this.state = {
            "show_string_services" : false
        }
    }


    bookService = async(service_type)=>{
        this.props.navigate('/stringing/book-service/'+service_type);
    }

    
    handleClose = async()=>{
        this.setState({"show_string_services" : false});
    }


    render() {
        return (
            <>
                <Modal show={this.state.show_string_services} onHide={()=>{this.handleClose()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Book Service For</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="justify-content-center d-flex align-items-center flex-column">
                        <Swiper slidesPerView={2} class="swiper-wrapper">
                            <SwiperSlide>
                                <div class="collection-item style-2 hover-img">
                                    <div class="collection-inner">
                                        <Link to={"/stringing/book-service/"+0} class="collection-image img-style rounded-0">
                                            <img class="lazyload" data-src="/assets/images/collections/collection-29.jpg" src="images/collections/collection-29.jpg" alt="collection-img"/>
                                        </Link>
                                        <div class="collection-content">
                                            <Link to={"/stringing/book-service/"+0} class="tf-btn collection-title hover-icon fs-15"><span>Badminton</span><i class="icon icon-arrow1-top-left"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div class="collection-item style-2 hover-img">
                                    <div class="collection-inner">
                                        <Link to={"/stringing/book-service/"+1} class="collection-image img-style rounded-0">
                                            <img class="lazyload" data-src="/assets/images/collections/collection-30.jpg" src="images/collections/collection-30.jpg" alt="collection-img"/>
                                        </Link>
                                        <div class="collection-content">
                                            <Link to={"/stringing/book-service/"+1} class="tf-btn collection-title hover-icon fs-15"><span>Tennis</span><i class="icon icon-arrow1-top-left"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </Modal.Body>
                </Modal>

                <section class="flat-spacing-14">
                    <div class="container">
                        <div class="flat-title wow fadeInUp" data-wow-delay="0s">
                            <span class="title">Shop by categories</span>
                        </div>
                        <div class="hover-sw-nav">
                            <div dir="ltr" class="swiper tf-sw-collection" data-preview="3" data-tablet="3" data-mobile="3" data-space-lg="30" data-space-md="30" data-space="15" data-loop="false" data-auto-play="false">
                                <Swiper slidesPerView={3} class="swiper-wrapper">
                                    <SwiperSlide>
                                        <div class="collection-item style-2 hover-img">
                                            <div class="collection-inner">
                                                <a href="javascript:;" onClick={()=>{this.setState({"show_string_services" : true})}} class="collection-image img-style rounded-0">
                                                    <img class="lazyload" data-src="/assets/images/collections/collection-29.jpg" src="images/collections/collection-29.jpg" alt="collection-img"/>
                                                </a>
                                                <div class="collection-content">
                                                    <a href="javascript:;" onClick={()=>{this.setState({"show_string_services" : true})}} class="tf-btn collection-title hover-icon fs-15"><span>Stringing</span><i class="icon icon-arrow1-top-left"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="collection-item style-2 hover-img">
                                            <div class="collection-inner">
                                                <Link to={"/shop/products"} class="collection-image img-style rounded-0">
                                                    <img class="lazyload" data-src="/assets/images/collections/collection-30.jpg" src="images/collections/collection-30.jpg" alt="collection-img"/>
                                                </Link>
                                                <div class="collection-content">
                                                    <Link to={"/shop/products"} class="tf-btn collection-title hover-icon fs-15"><span>Shop</span><i class="icon icon-arrow1-top-left"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="collection-item style-2 hover-img">
                                            <div class="collection-inner">
                                                <Link to={"/stadiums"} class="collection-image img-style rounded-0">
                                                    <img class="lazyload" data-src="/assets/images/collections/collection-16.jpg" src="images/collections/collection-16.jpg" alt="collection-img"/>
                                                </Link>
                                                <div class="collection-content">
                                                    <Link to={"/stadiums"} class="tf-btn collection-title hover-icon fs-15"><span>Stadiums</span><i class="icon icon-arrow1-top-left"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flat-spacing-7 flat-iconbox">
                    <div class="container">
                        <div class="wrap-carousel wrap-mobile wow fadeInUp" data-wow-delay="0s">
                            <div dir="ltr" class="swiper tf-sw-mobile" data-preview="1" data-space="15">
                                <div class="swiper-wrapper wrap-iconbox">
                                    <div class="swiper-slide">
                                        <div class="tf-icon-box style-border-line text-center">
                                            <div class="icon">
                                                <i class="icon-shipping"></i>
                                            </div>
                                            <div class="content">
                                                <div class="title">Free Shipping</div>
                                                <p>Free shipping over order $120</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="tf-icon-box style-border-line text-center">
                                            <div class="icon">
                                                <i class="icon-payment fs-22"></i>
                                            </div>
                                            <div class="content">
                                                <div class="title">Flexible Payment</div>
                                                <p>Pay with Multiple Credit Cards</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="tf-icon-box style-border-line text-center">
                                            <div class="icon">
                                                <i class="icon-return fs-22"></i>
                                            </div>
                                            <div class="content">
                                                <div class="title">14 Day Returns</div>
                                                <p>Within 30 days for an exchange</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="tf-icon-box style-border-line text-center">
                                            <div class="icon">
                                                <i class="icon-suport"></i>
                                            </div>
                                            <div class="content">
                                                <div class="title">Premium Support</div>
                                                <p>Outstanding premium support</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                            </div>
                            <div class="sw-dots style-2 sw-pagination-mb justify-content-center"></div>
                        </div>
                    </div>
                </section>
            </>        
        )
    }
}

export default Services;









        