import React, { Component } from "react";
import withRouter from '../Services/WithRouter';
import Api from '../Services/StringApi';
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";

class BookService extends Component{

    pickup_times =  [
        { label: '11:00 AM - 2:00 PM', value: '0' },
        { label: '7:00 PM - 9:00 PM', value: '1' }
    ];

    componentDidMount =()=>{
        
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(prevProps.latitude != this.state.latitude || prevProps.address != this.state.address || prevProps.longitude != this.state.longitude || this.props.params.service_type != this.state.service_type){
            this.getServiceType(prevProps.latitude, prevProps.longitude, prevProps.address, this.props.params.service_type);
        }
    }



    getServiceType = async(latitude, longitude, address, service_type)=>{
        console.log("hereee")
        await this.setState({'service_type' : service_type, "latitude" : latitude, "longitude" : longitude, 'address' : address, 'string_types' : [], "string_items" : [{"racket_type" : "", "colors" : [], "color" : "", "string_type" : "", "string_type_name" : "", "string_price" : 0, 'quantity' : "", 'tension' : "", "error" : ""}]}, ()=>{
            this.getStringTypes();
        });
        
    }


    constructor(props){
        super(props);
        this.state={
           "service_type" : "",
           "name" : "",
           "address" : "",
           "latitude" : "",
           "longitude" : "",
           "slot_date" : "",
           "slot_time" : "",
           'string_types' : [],
           "string_items" : [
                {"racket_type" : "", "colors" : [], "color" : "", "string_type" : "", "string_type_name" : "", "string_price" : 0, 'quantity' : "", 'tension' : "", "error" : ""}
            ],
            "show_string_types" : false
        }
        
    }


    getStringTypes = async()=>{
        console.log(this.state)
        if(this.state.latitude && this.state.longitude && this.state.service_type){
            var data = {
                'searched_text' : "",
                'latitude' : this.state.latitude,
                'longitude' : this.state.longitude,
                'service_type' : this.state.service_type
            }
            var response = await Api.GetRequest(data, 'string-types');
            if(response.status == 200){
                this.setState({'string_types' : response.string_types});
            }
            else{
    
            }
        }
       
    }  


    addMoreVariants = async()=>{
        var variants = this.state.string_items;
        await variants.push({"racket_type" : "", "colors" : [], "color" : "", "string_type" : "", "string_type_name" : "", "string_price" : 0, 'quantity' : "", 'tension' : "", "error" : ""})
        this.setState({'string_items' : variants});
    }


    deleteVariant = async(index)=>{
        var variants = await this.state.string_items;
        variants.splice(index, 1);
        this.setState({'string_items' : variants});
    }


    changeVariantText = async(value, index, key) =>{
        var variants = await this.state.string_items;
        variants[index][key] = value;
        this.setState({'string_items' : variants});
    }


    changeStringType  =async(item)=>{
        var variants = this.state.string_items;
        console.log(item)
        variants[this.state.selected_string_type]['colors'] = item.string_variants;
        variants[this.state.selected_string_type]['string_price'] = item.price;
        variants[this.state.selected_string_type]['string_type'] = item.id;
        variants[this.state.selected_string_type]['string_type_name'] = item.name; 
        variants[this.state.selected_string_type]['color'] = "";
        this.setState({'string_items' : variants, 'this.state.selected_string_type' : "", 'show_string_types' : false});  
    }

    checkAddress = async(index)=>{
        if(this.state.address && this.state.address.length == 0){
            await toast.error("Please select the address to get the string types.");   
        }
        else{
            this.setState({'selected_string_type' : index, 'show_string_types' : true});
        }
    }

    handleCloseStringTypes = async()=>{
        this.setState({'show_string_types' : false})
    }


    validateForm = async()=>{
        if(this.state.name.trim().length < 2){
            await toast.error("Please enter valid name.");
        }
        else if(this.state.slot_time == ""){
            await toast.error("Please select slot time.");
        }
        else if(this.state.address == ""){
            await toast.error("Please select address");
        }
        else if(this.state.string_items.length == 0){
            await toast.error("Please add variants.");   
        }
        else{
            var status = true;
            var string_items = this.state.string_items;
            await string_items.forEach((item)=>{
                if(item.racket_type.trim().length == 0 || item.string_type == "" || item.color == "" || item.quantity == "" || item.tension == ""){
                    item.error = "Please fill all the details properly.";
                    status = false
                }
                else{
                    item.error = "";
                }
            })

            await this.setState({'string_items' : string_items});

            if(status == true){
                this.checkout();
            }
            else{
                await toast.error("Please fill all the details properly for variants.");     
            }

        }
    }


    checkout = async()=>{
        var data = {
            "name" : this.state.name.trim(),
            "slot_date" : moment(this.state.slot_date).format("DD-MM-YYYY"),
            "slot_time" : this.state.slot_time,
            "address" : this.state.address.trim(),
            "latitude" : this.state.latitude,
            "longitude" : this.state.longitude,
            "string_items" : this.state.string_items,
            "service_type" : this.state.service_type
        }    
        this.props.navigate('/stringing/checkout', {'state' : data})
    }


    render(){
        return(
            <>

                <Modal show={this.state.show_string_types} onHide={()=>{this.handleCloseStringTypes()}}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize:'15px'}}>String types</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{"backgroundColor": "#EDEDED"}} className="justify-content-center d-flex align-items-center flex-column">
                        {this.state.string_types.map((string_type)=>{
                            return(
                                <div onClick={()=>{this.changeStringType(string_type)}} className="card my-2 w-100">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-3">
                                                <img src={process.env.REACT_APP_MEDIA_URL + string_type.image}/>
                                            </div>  
                                            <div className="col-9 d-flex flex-column">
                                                <b className="my-2" style={{'fontWeight' : "bold"}}>{string_type.name}</b>
                                                <b className="my-2" style={{'fontWeight' : "bold"}}> ₹ {string_type.price}</b>
                                            </div>
                                            <div className="col-12">
                                                <p style={{"fontSize" : '13px'}}>{string_type.description}</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            )
                        })}
                        {this.state.string_types.length == 0 &&
                            <div class="card">
                                <div className="card-body">
                                    <b>No string types availbale at your location. Please change the location and try again.</b>
                                </div>
                            </div>

                        }
                    </Modal.Body>
                </Modal>

                <div className="container padding-bottom-1x mb-1 ">
                    <div className="card mt-5 px-5">
                        <div className="row">
                            <div className="col-12 col-md-4 my-3">
                                <label className="w-100" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}><span className="input-group-addon"><i className="icon-user"></i></span> Name</label>
                                <div className="input-group mt-2">
                                    <input onChange={(event) => {this.setState({"name" : event.target.value})}} className="form-control" type="text" name="name" placeholder=""/>
                                </div>
                            </div>

                            <div className="col-12 col-md-4 my-3">
                                <label className="w-100" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}><span className="input-group-addon"><i className="icon-calendar"></i></span> Booking Date</label>
                                <div className="input-group mt-2">
                                    <input onChange={(event) => {this.setState({"slot_date" : event.target.value})}} className="form-control" type="date" name="date" placeholder=""/>
                                </div>
                            </div>

                            <div className="col-12 col-md-4 my-3">
                                <label className="w-100" style={{'textAlign' : 'left', 'fontWeight' : "bold"}}><span className="input-group-addon"><i className="icon-clock"></i></span> Booking Time</label>
                                <div className="input-group mt-2">
                                    <select className="form-control" onChange={(event)=>{this.setState({slot_time : event.target.value})}} value={this.state.slot_time} name="slot_time">
                                        <option value=""></option>
                                        {this.pickup_times.map((time)=>{
                                            return(
                                                <option value={time.value}>{time.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="my-3">
                        <div className="justify-content-between d-flex align-items-center flex-row py-2 px-3" style={{backgroundColor : "#EDEDED"}}>
                            <h5 className="mb-0" style={{'fontWeight' : "bold", backgroundColor : "#EDEDED"}}>Variants</h5>
                            <button onClick={()=>{this.addMoreVariants()}} class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center"><span>ADD</span></button>
                        </div>
                    </div>
                        
                    <div className="row">
                        {this.state.string_items.map((variant, index)=>{
                            return(
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="card my-2">
                                        <div className="row card-body">
                                            <div className="w-100 justify-content-end d-flex align-items-end">
                                                <i onClick={()=>{this.deleteVariant(index)}} class="fa fa-times" style={{'fontSize' : '20px', color:'#000000'}}></i>
                                            </div>
                                            <div class="col-sm-6 mt-2">
                                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                                    <label for="checkout-fn">Racket type *</label>
                                                    <input class="form-control mt-2" value={variant.racket_type} onChange={(event)=>{this.changeVariantText(event.target.value, index, 'racket_type')}} name="bill_name" type="text" id="checkout-fn" required/>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 mt-2">
                                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                                    <label for="checkout-fn">String type *</label>
                                                    <input class="form-control mt-2"  value={variant.string_type_name} onClick={(event)=>{this.checkAddress(index)}} name="bill_name" type="text" id="checkout-fn" required/>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 mt-2">
                                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                                    <label for="checkout-fn">Color *</label>
                                                    <select class="form-control attribute_option mt-2" value={variant.color} onChange={(event)=>{this.changeVariantText(event.target.value, index, "color")}} id="Color">
                                                        <option value=""></option>  
                                                        {variant.colors.map((color)=>{
                                                            return(
                                                                <option value={color.color}>{color.color}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 mt-2">
                                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                                    <label for="checkout-fn">Quantity *</label>
                                                    <input class="form-control mt-2" value={variant.quantity} min={1} onChange={(event)=>{this.changeVariantText(event.target.value, index, "quantity")}} name="bill_name" type="number" id="checkout-fn" required/>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 mt-2">
                                                <div class="form-group justify-content-start d-flex align-items-start flex-column">
                                                    <label for="checkout-fn">Tension *</label>
                                                    <input class="form-control mt-2" value={variant.tension} min={1} onChange={(event)=>{this.changeVariantText(event.target.value, index, "tension")}} name="bill_name" type="text" id="checkout-fn" required/>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                {variant.error &&
                                                    <small className="d-flex" style={{'color' : "red", width:'100%', 'textAlign' : "left"}}> *{variant.error}</small>
                                                }
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                            )
                        })}
                    
                    </div>


                     <button onClick={()=>{this.validateForm()}} id="continue__button" class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center  mt-2" type="button"><span>Checkout</span></button>  
                </div>
            </>
        )
    }

}


export default  withRouter(BookService);