import React from 'react'
import EcomApi from '../Services/EcomApi';
import StringApi from '../Services/StringApi';
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";
import withRouter from "../Services/WithRouter";


class Profile extends React.Component {

    componentDidMount = async()=>{
        this.getBookings();
        this.getOrders();
    }

    constructor(props){
        super(props);
        this.state = {
            'bookings' : [],
            'orders' : [],
            'section' : 0,
            'active_page' : 1
        }
    }


    getBookings = async()=>{
        var response = await StringApi.GetRequest({}, 'string/bookings');
        if(response.status == 200){
            this.setState({'bookings' : response.bookings});
        }
        else{
            await toast.error("Something went wrong. Please try again later.");
        }
    }


    getOrders = async()=>{
        var response = await EcomApi.GetRequest({}, 'orders');
        if(response.status == 200){
            this.setState({'orders' : response.orders});
        }
        else{
            await toast.error("Something went wrong. Please try again later.");
        }
    }


    setSection = async(section)=>{
        this.setState({'section' : section});
    }

    logout = async()=>{
        localStorage.clear();
        this.props.navigate("/home");
        this.props.checkUserLogin();
    }




    
    
    render() {
        return (
            <>
                <div class="tf-page-title">
                    <div class="container-full">
                        <div class="heading text-center">My Account</div>
                    </div>
                </div>

                <section class="flat-spacing-11">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="wrap-sidebar-account">
                                    <ul class="my-account-nav">
                                        <li><a href="javascript:;" onClick={()=>{this.setSection(0)}} className={`my-account-nav-item ${this.state.section == 0 ? 'active' : ''}`}>Dashboard</a></li>
                                        <li><a href="javascript:;" onClick={()=>{this.setSection(1)}} className={`my-account-nav-item ${this.state.section == 1 ? 'active' : ''}`}>Orders</a></li>
                                        <li><a href="javascript:;" onClick={()=>{this.setSection(2)}} className={`my-account-nav-item ${this.state.section == 2 ? 'active' : ''}`}>Services</a></li>
                                        <li><a href="javascript:;" onClick={()=>{this.logout()}} className={`my-account-nav-item ${this.state.section == 3 ? 'active' : ''}`}>Logout</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div class="my-account-content account-dashboard">
                                    {this.state.section == 0 &&
                                        <div class="mb_60 justify-content-start d-flex align-items-start flex-column">
                                            <h5 class="fw-5 mb_20">Hello</h5>
                                            <p>
                                                From your account dashboard you can view your <a class="text_primary" onClick={()=>{this.setSection(1)}} href="javascript:;"> orders</a>, and <a class="text_primary" onClick={()=>{this.setSection(2)}} href="javascript:;">services</a>.
                                            </p>
                                        </div>
                                    }

                                    {this.state.section == 1 &&
                                        <>
                                            <div class="card">
                                                <div class="card-body">
                                                    {this.state.orders.length > 0 &&
                                                        <div class="my-account-content account-order">
                                                            <div class="wrap-account-order">
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="fw-6">Order #</th>
                                                                            <th class="fw-6">Price</th>
                                                                            <th class="fw-6">OTP</th>
                                                                            <th class="fw-6">Ordered At</th>
                                                                            <th class="fw-6">Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.orders.slice(((this.state.active_page - 1 )* 10), (this.state.active_page * 10)).map((order)=>{
                                                                            return(
                                                                                <tr class="tf-order-item">
                                                                                    <td><a class="navi-link" href="#" data-toggle="modal" data-target="#orderDetails">{order.order_id}</a></td>
                                                                                    <td>₹ {order.price - order.coupon_discount - order.referral_discount - order.discount}</td>
                                                                                    <td><b>{order.otp}</b></td>
                                                                                    <td>{moment(order.slot_date).format('ll')} <span>{order.slot_time == 0 ? "11:00 AM - 2:00 PM" : "7:00 PM - 9:00 PM"}</span></td>
                                                                                    <td><Link to={`/profile/order/${order.id}`} class="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">Invoice</Link></td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                                <Pagination
                                                                    activePage={this.state.active_page}
                                                                    itemsCountPerPage={10}
                                                                    totalItemsCount={this.state.orders.length}
                                                                    // pageRangeDisplayed={Math.round(this.state.active_page / 5)}
                                                                    onChange={(page)=>{
                                                                        this.setState({'active_page' : page});
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.orders.length == 0 &&
                                                        <img src="/assets/images/no_orders.png" style={{'width' : '56%'}}/>
                                                    }
                                                </div>
                                            </div>
                                            
                                            
                                            {this.state.bookings.length == 0 &&
                                                <img src="/assets/images/no_orders.png" style={{'width' : '56%'}}/>
                                            }
                                        </>
                                                
                                           
                                    }

                                    {this.state.section == 2 &&
                                        <div class="card">
                                            <div class="card-body">
                                                {this.state.bookings.length > 0 &&
                                                    <div class="my-account-content account-order">
                                                        <div class="wrap-account-order">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="fw-6">Order</th>
                                                                        <th class="fw-6">Mobile</th>
                                                                        <th class="fw-6">Name</th>
                                                                        <th class="fw-6">OTP</th>
                                                                        <th class="fw-6">Date</th>
                                                                        <th class="fw-6">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.bookings.slice(((this.state.active_page - 1 )* 10), (this.state.active_page * 10)).map((order)=>{
                                                                        return(
                                                                            <tr class="tf-order-item">
                                                                                <td><a class="navi-link" href="#" data-toggle="modal" data-target="#orderDetails">{order.id}</a></td>
                                                                                <td>{order.mobile}</td>
                                                                                <td>{order.name}</td>
                                                                                <td><b>{order.otp}</b></td>
                                                                                <td>{moment(order.slot_date).format('ll')} <span>{order.slot_time == 0 ? "11:00 AM - 2:00 PM" : "7:00 PM - 9:00 PM"}</span></td>
                                                                                <td><Link to={`/profile/service/${order.id}`} class="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">Invoice</Link></td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.orders.length == 0 &&
                                                    <img src="/assets/images/no_orders.png" style={{'width' : '56%'}}/>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(Profile);