import React from 'react'
import 'swiper/css';
import withRouter from '../Services/WithRouter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Addresses from '../Addresses/addresses';
import EditAddress from '../Addresses/edit_address';
import NewAddress from '../Addresses/new_address';
import Api from '../Services/EcomApi';

class Checkout extends React.Component {


    componentDidMount = async()=>{
        await this.getCart();
        await this.getAddresses();
    }


    getCart = async()=>{
        var response = await Api.GetRequest({}, 'cart');
        if(response.status == 200){
            var discount_type = this.props.location.state.discount_type || "";
            var coupon_id = this.props.location.state.coupon_id || "";
            var refer_code = this.props.location.state.refer_code || "";
            var coupon_discount = parseInt(this.props.location.state.coupon_discount) || 0;
            var referral_discount = parseInt(this.props.location.state.referral_discount) || 0;
            await this.setState({
                'cart' : response.cart,
                'total_discount' : response.total_discount,
                'total_amount' : response.total_amount,
                'total_price' : response.total_price,
                'discount_type' : discount_type,
                'coupon_id' : coupon_id,
                'coupon_discount' : coupon_discount,
                'referral_discount' : referral_discount,
                'refer_code' : refer_code
            });
        }
        else{
            await toast.showToast("error", "Something went wrong. Please try again later.");
        }
    }


    verifyUser = async()=>{
        var response = await Api.GetRequest({}, 'verify-user');
        if(response.status == 401){
            this.props.navigation.navigate("/home");
        }
    }


    getAddresses = async()=>{
        var response = await Api.GetRequest({}, 'addresses');
        if(response.status == 200){
            await this.setState({'addresses' : response.addresses, 'address' : {}, 'selected_delivery_address' : ""}, ()=>{
                 this.setDeliveryAddress();
            });
            
        }
        else{

        }
    }


    constructor(props){
        super(props);
        this.state = {
            'cart' : [],
            'coupon' : {},
            'total_amount' : 0,
            'total_price' : 0,
            'total_discount' : 0,
            'coupon_discount' : 0,
            'shipping_charge' : 0,
            'addresses' : [],
            'address' : {
                'id' : ""
            },
            'order_id' : "",
            'amount' : "",
            'is_open' : false,
            'stock_error' : false,
            "selected_delivery_address" : "",
            "edit_address" : {},
            "show_edit_address" : false,
            "payment_mode" : 0,
            "show_addresses" : false,
            'refer_code' : "",
            'coupon_id' : "",
            'discount_type' : "",
            'referral_discount' : 0,
            'coupon_discount' : 0
        }
    }


    setDeliveryAddress = async()=>{
        if(this.state.selected_delivery_address){
            var selected_index = await this.state.addresses.findIndex((address)=>{return (address.id == this.state.selected_delivery_address)});
            if(selected_index > -1){
                this.setState({
                    'address' : this.state.addresses[selected_index]
                });
            }
        }
        else{
            var delivery_index = await this.state.addresses.findIndex((address)=>{return address.is_delivery_address == 1});
        
            if(delivery_index > -1){
                this.setState({
                    'address' : this.state.addresses[delivery_index],
                    'selected_delivery_address' : this.state.addresses[delivery_index].id
                });
            }
            else{
                var default_index = await this.state.addresses.findIndex((address)=>{return address.is_default == 1});
                if(default_index > -1){
                    this.setState({
                        'address' : this.state.addresses[default_index],
                        'selected_delivery_address' : this.state.addresses[default_index].id
                    });
                }
                else{
                    if(this.state.addresses.length > 0){
                        this.setState({
                            'address' : this.state.addresses[0],
                            'selected_delivery_address' : this.state.addresses[0].id
                        });
                    }
                }
            }
        }
       
    }



    generateOrder = async()=>{
        var data = {
            'coupon_discount' : this.state.coupon_discount,
            'referral_discount' : this.state.referral_discount
        }
        var response = await Api.PostRequest(data, 'create-order');
        if(response.status == 200){
            if(this.state.payment_mode == 0){
                this.makePayment(response.cart_items, response.order, response.razorpay_key_id)
            }
            else{
                this.placeOrder(response.cart_items, "")   
            }   
        }
        else{
            await toast.error("Something went wrong. Please try again later.");
        }
    }

    makePayment = async(cart, order, RAZORPAY_KEY_ID)=>{
        var options = {
            description: 'Credits towards consultation',
            image: 'https://i.imgur.com/3g7nmJC.jpg',
            currency: 'INR',
            key: RAZORPAY_KEY_ID,
            amount: order.amount.toString(),
            name: "SPORTITOR",
            order_id: order.id,//Replace this with an order_id created using Orders API.
            prefill: {
              email: '',
              contact: '',
              name: ''
            },
            theme: {color: '#000000'},
            handler: async (data) => {
                try {
                    this.verifyPayment(data, cart);
                } catch (err) {
                  console.log(err);
                }
            },
        }

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        
    }


    verifyPayment = async(payment_data, cart)=>{
        var response = await Api.PostRequest(payment_data, 'payment-verification');
        if(response.status == 200){
            this.placeOrder(cart, response.transaction_id)
        }
        else{
            await toast.error("Payment not verify. Please contact to admin.");
        }
    }

    placeOrder = async(cart, transaction_id)=>{
        var data = {
            'name' : this.state.address.name,
            'email' : this.state.address.email,
            'address' : this.state.address.address,
            'zipcode' : this.state.address.zipcode,
            'mobile' : this.state.address.phone,
            'city' : this.state.address.city, 
            'state' : this.state.address.state,
            'transaction_id' : transaction_id,
            'cart' : cart,
            'address_id' : this.state.address.id,
            'payment_mode' : this.state.payment_mode,
            'refer_code' : this.state.refer_code,
            'coupon_id' : this.state.coupon_id,
            'referral_discount' : this.state.referral_discount,
            'coupon_discount' : this.state.coupon_discount
        }

        var response = await Api.PostRequest(data, 'place-order');
        if(response.status == 200){
            await toast.success("Order placed successfully..");
            await this.setState({
                'cart' : [],
                'total_amount' : 0,
                'total_price' : 0,
                'total_discount' : 0,
                'addresses' : [],
                'address' : {},
                'order_id' : "",
                'amount' : "",
                'is_open' : false,
                "edit_address" : {},
                "show_addresses" : false,
                "show_edit_address" : false,
                'refer_code' : "",
                'coupon_id' : "",
                'referral_discount' : 0,
                'coupon_discount' : 0
            });
            this.props.navigate('/')
            this.props.fetchCart();
        }
    }


    handleLogin = async()=>{
       this.setState({'show_login' : false});
    }

    showAddresses = async()=>{
        await this.getAddresses();
        this.setState({'show_addresses' : true})
    }
 
    
    handleAddress = async(address)=>{
        if(Object.keys(address).length > 0){
            this.setState({
               "address" : address
            })
        }
        this.setState({'show_addresses' :  false});
    }


    addNewAddress = async()=>{
        this.setState({'show_new_address' : true}); 
    }


    handleNewAddress = async()=>{
        await this.getAddresses();
        this.setState({'show_new_address' :  false});
    }


    markDefault = async()=>{
        if(this.state.is_default == 1){
            this.setState({'is_default' : 0});
        }
        else{
            this.setState({'is_default' : 1});
        }
    }


    checkStock = async() =>{
        var response = await Api.GetRequest({}, 'check-stock');
        if(response.status == 200){
            this.generateOrder();
        }
        else if(response.status == 406){
            this.setState({'stock_error' : true});
        }
        else{
            await toast.error(response.message);
        }
    }


    editAddress = async(address)=>{
        this.setState({
            'edit_address' : address,
            'show_edit_address' : true
        });
    }


    handleEditAddress = async()=>{
        await this.getAddresses();
        this.setState({'show_edit_address' :  false});
    }


    deleteAddress = async(address_id)=>{
        var response = await Api.DeleteRequest('address/'+address_id);
        if(response.status == 200){
            await toast.success("Address deleted successfully.");
            await this.setState({'show_address' : false});
            await this.getAddress();
        }
        else{
            toast.error(response.message)
        }
    }



    closeEditAddress = async()=>{
        await this.getAddress();
        this.setState({'show_edit_address' : false})
    }


    setPaymentMode = async() =>{
        this.setState({'payment_mode' : this.state.payment_mode == 0 ? 1:0});
    }


    render() {
        return (
            <>

                <Addresses show_addresses={this.state.show_addresses} editAddress={this.editAddress} getAddresses={this.getAddresses} selected_delivery_address={this.state.selected_delivery_address} addresses={this.state.addresses} handleAddress={this.handleAddress} addNewAddress={this.addNewAddress}></Addresses>
                <NewAddress show_new_address={this.state.show_new_address} handleNewAddress={this.handleNewAddress}></NewAddress>
                <EditAddress show_edit_address={this.state.show_edit_address} address={this.state.edit_address} handleEditAddress={this.handleEditAddress}></EditAddress>
                
                <div class="tf-page-title">
                    <div class="container-full">
                        <div class="heading text-center">Check Out</div>
                    </div>
                </div>
                
                <section class="flat-spacing-11">
                    <div class="container">
                        <div class="tf-page-cart-wrap layout-2">
                            <div class="tf-page-cart-item">
                                <div className='justify-content-between d-flex align-items-center flex-row'>
                                    <h5 class="fw-5 mb_20">Billing details</h5>
                                    <button onClick={()=>{this.showAddresses()}} class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center"><i class="icon-home"></i>&emsp;<span class="hidden-xs-down ml-5">Change</span></button>
                                </div>
                                
                                
                                {Object.keys(this.state.address).length > 0 &&
                                    <div style={{'border' : '1px dashed #000000', cursor : "pointer"}} className="card w-100 justify-content-start d-flex align-items-start px-3 py-3 mt-5" >
                                        <p className="mb-0">{this.state.address.name}</p>
                                        <p className="mb-0">{this.state.address.phone}</p>
                                        <p className="mb-0">{this.state.address.address}</p>
                                        <p className="mb-0">{this.state.address.city}, {this.state.address.zipcode}</p>
                                    </div>
                                }

                                {Object.keys(this.state.address).length == 0 &&
                                    <div style={{'border' : '1px dashed #000000', cursor : "pointer"}} className="card w-100 justify-content-center d-flex align-items-center my-2 px-3 py-3 mt-5" >
                                        <div class="tf-page-cart text-center mt_140 mb_200">
                                            <h5 class="mb_24">Please add address</h5>
                                        </div> 
                                    </div>
                                }
                            </div>
                            <div class="tf-page-cart-footer">
                                <div class="tf-cart-footer-inner">
                                    <h5 class="fw-5 mb_20">Your order</h5>
                                    <div class="tf-cart-totals-discounts card mt-5">
                                        <table className='table table-bordered w-100'>
                                            <tbody>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>MRP Total</th>
                                                    <td style={{'textAlign' : 'right'}}>₹ {this.state.total_price.toFixed(0)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Discount</th>
                                                    <td style={{'textAlign' : 'right'}}>-₹ {this.state.total_discount.toFixed(0)}</td>
                                                </tr>
                                                {this.state.discount_type == 1 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Referral Discount</th>
                                                        <td style={{'textAlign' : 'right'}}>-₹ {this.state.referral_discount.toFixed(0)}</td>
                                                    </tr>
                                                }
                                                {this.state.discount_type == 2 &&
                                                    <tr>
                                                        <th style={{'textAlign' : 'left'}}>Coupon Discount</th>
                                                        <td style={{'textAlign' : 'right'}}>-₹ {this.state.coupon_discount.toFixed(0)}</td>
                                                    </tr>
                                                }
                                                
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>To Pay</th>
                                                    <td style={{'textAlign' : 'right'}}>
                                                        {this.state.total_discount > 0 &&
                                                            <span style={{'fontWeight' : "bold", "textDecorationLine" : "line-through", 'marginRight' : '4%'}}>₹ {(this.state.total_price - this.state.referral_discount - this.state.coupon_discount).toFixed(0)}</span>
                                                        }
                                                        <span style={{'fontWeight' : "bold"}}>₹ {(this.state.total_price - this.state.total_discount - this.state.referral_discount - this.state.coupon_discount).toFixed(0)}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="fieldset-radio mb_20 w-100 justify-content-start d-flex px-3" style={{'textAlign' : "left"}}>
                                            <input type="checkbox" name="payment" id="delivery" class="tf-check" value={this.state.payment_mode} onChange={(e)=>{this.setPaymentMode()}}/>
                                            <label for="delivery" style={{'marginLeft' : '10px'}}>Cash on delivery</label>
                                        </div>
                                        <p class="text_black-2 mb_20">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a href="privacy-policy.html" class="text-decoration-underline">privacy policy</a>.</p>
                                            
                                        <button disabled={Object.keys(this.state.address).length == 0} onClick={()=>{this.generateOrder()}} class="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center">Place order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>        
        )
    }
}

export default withRouter(Checkout);


